<template>
    <v-card class="mt-4" outlined>
        <v-toolbar color="blue-grey lighten-4">
            <v-toolbar-title>Quote Details - Order {{ order.name }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
            <v-row no-gutters>
                <v-col>
                    <v-card>
                        <v-card-title>Ship To:</v-card-title>
                        <v-card-text>
                            <div>{{ order.shipping_address.address1 }}</div>
                            <div>{{ order.shipping_address.address2 }}</div>
                            <div>{{ order.shipping_address.city }} {{ order.shipping_address.province_code }} {{ order.shipping_address.zip }} {{ order.shipping_address.country_code }}</div>                        
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-2">
                        <v-card-title>Ship From:</v-card-title>
                        <v-card v-for="(item, index) in locationsWithLineItems" :key="index" class="ma-2" outlined>
                            <v-card-title>{{ item.location.name }}</v-card-title>
                            <v-row no-gutters>
                                <v-col cols="3">
                                    <v-card-subtitle>
                                        <div>{{ item.location.address1 }}</div>
                                        <div>{{ item.location.address2 }}</div>
                                        <div>{{ item.location.city }} {{ item.location.province_code }} {{ item.location.zip }} {{ item.location.country_code }}</div>                        
                                    </v-card-subtitle>
                                </v-col>
                                <v-col cols="9">
                                    <v-card-text>
                                        <v-row no-gutters class="font-weight-bold">
                                            <v-col cols="2" class="pr-4 text-right">Qty</v-col>
                                            <v-col cols="6" class="pl-2">Title</v-col>
                                            <v-col cols="2" class="text-right">Net Weight</v-col>
                                            <v-col cols="2" class="text-right">Total Weight</v-col>
                                        </v-row>
                                        <v-row no-gutters v-for="(product, idx) in item.lineItems" :key="idx">
                                            <v-col cols="2" class="pr-4 text-right">{{ product.quantity }}</v-col>
                                            <v-col cols="6" class="pl-2">{{ product.title }}<div v-if="product.sku"><small>{{ product.sku }}</small></div></v-col>
                                            <v-col cols="2" class="text-right">{{ product.grams / 1000 | formatNumber(2) }}kg</v-col>
                                            <v-col cols="2" class="text-right">{{ (product.grams * product.quantity) / 1000 | formatNumber(2) }}kg</v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card-text>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card>Products</v-card>

                </v-col>
            </v-row>
        </v-container>
        <v-card outline>
            <Response v-if="order && order.fulfillment_orders":data="locationsWithLineItems"/>
        </v-card>
        
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import { getSessionToken } from '@shopify/app-bridge-utils';
import {graphql} from "@/V2/helpers/shopify";
import Response from '@/V2/components/Utilities/Response'

export default {
    props: ['order'],
    data: function () {
        return {
        }
    },
    components: {
        Response
    },
    methods: {
        findLocationById: function (fulfillmentOrders, lineItemId) {
            for (const order of fulfillmentOrders) {
                for (const lineItem of order.line_items) {
                    if (lineItem.line_item_id === lineItemId) {
                        return order.assigned_location;
                    }
                }
            }
            return null;
        },
    },
    created() {
    },
    computed: {
        ...mapState('LiveRates', ['settings']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
        locationsWithLineItems() {
            const locationsMap = {};
            const itemTracking = {};
console.log('1')
            this.order.line_items.forEach(item => {
                itemTracking[item.id] = item.quantity;
            });
            console.log('2', this.order)

            if(this.order.fulfillment_orders) {
                this.order.fulfillment_orders.forEach(fulfillmentOrder => {
                    const locationKey = JSON.stringify(fulfillmentOrder.assigned_location);
                    fulfillmentOrder.line_items.forEach(lineItem => {
                        if (itemTracking[lineItem.line_item_id] && itemTracking[lineItem.line_item_id] > 0) {
                            if (!locationsMap[locationKey]) {
                                locationsMap[locationKey] = {
                                    location: fulfillmentOrder.assigned_location,
                                    lineItems: []
                                };
                            }
                            const quantityToAdd = Math.min(lineItem.quantity, itemTracking[lineItem.line_item_id]);
                            const detailedLineItem = this.order.line_items.find(item => item.id === lineItem.line_item_id);
                            if (detailedLineItem) {
                                locationsMap[locationKey].lineItems.push({
                                    ...detailedLineItem,
                                    quantity: quantityToAdd
                                });
                                itemTracking[lineItem.line_item_id] -= quantityToAdd;
                            }
                        }
                    });
                });

                return Object.values(locationsMap);
            } 
            return {}
        }      
    }
}
</script>

