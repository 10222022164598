<template>
    <v-row>
        <v-col cols="12" lg="9">
            <v-card outlined>
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>Default Settings</v-toolbar-title>
                </v-toolbar>
                <div class="pa-3">
                    <v-row>
                        <v-col class="font-weight-bold">
                            <div>Handling time in days</div>
                            <div class="caption">Your handling time will be added to any estimated delivery dates provided by your connected carriers.</div>
                        </v-col>
                        <v-col>
                            <v-text-field ref="handling_time" dense outlined label="Handling time in days" v-model="defaults.handling_time" :rules="[rules.numberOrEmpty]" @change="save"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">
                            <div>Cut-off for handling time</div>
                            <div class="caption">If a shipping rate is requested after the cut-off time 1 day will be added to the handling time.</div>
                        </v-col>
                        <v-col>
                            <v-select 
                                v-model="defaults.cutoff"
                                ref="cutoff"
                                :items="cutoff_times"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ bottom: true, offsetY: true }"
                                outlined 
                                dense
                                hide-details
                                label="Selected Time"
                                placeholder="Select a cut-off time"
                                class="mb-0 caption"
                                @change="save"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">
                            <div>Default item dimensions (cm)</div>
                            <div class="caption">Item dimensions used if not specifically set for the product.</div>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col class="py-0">
                                    <v-text-field ref="length" dense outlined label="Length" v-model="defaults.length" :rules="[rules.numberOrEmpty]" @change="save"></v-text-field>
                                </v-col>
                                <v-col class="py-0">
                                    <v-text-field ref="width" dense outlined label="Width" v-model="defaults.width" :rules="[rules.numberOrEmpty]" @change="save"></v-text-field>
                                </v-col>
                                <v-col class="py-0">
                                    <v-text-field ref="height" dense outlined label="Height" v-model="defaults.height" :rules="[rules.numberOrEmpty]" @change="save"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">
                            <div>Default item weight (kg)</div>
                            <div class="caption">Item weight used if weight has not been set for the product.</div>
                        </v-col>
                        <v-col>
                            <v-text-field ref="weight" dense outlined label="Default Item Weight" v-model="defaults.item_weight" :rules="[rules.numberOrEmpty]" @change="save"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">

                        </v-col>
                        <v-col>

                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <DimensionsSettings v-if="0"/>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import DimensionsSettings from '@/V2/components/LiveRates/DimensionsSettings.vue'

export default {
    data: function () {
        return {
            defaults: {},
            rules: {
                required: value => !!value || 'Required.',
                isNumber: value => !isNaN(value) || 'Must be a number.',
                numberOrEmpty: value => null || !isNaN(value) || 'Must be a number.',
                isInteger: value => Number.isInteger(value) || 'Must be an integer value.'
            },
            formHasErrors: false,
            cutoff_times: [
                {id: 1, name: '1am'},
                {id: 2, name: '2am'},
                {id: 3, name: '3am'},
                {id: 4, name: '4am'},
                {id: 5, name: '5am'},
                {id: 6, name: '6am'},
                {id: 7, name: '7am'},
                {id: 8, name: '8am'},
                {id: 9, name: '9am'},
                {id: 10, name: '10am'},
                {id: 11, name: '11am'},
                {id: 12, name: '12pm'},
                {id: 13, name: '1pm'},
                {id: 14, name: '2pm'},
                {id: 15, name: '3pm'},
                {id: 16, name: '4pm'},
                {id: 17, name: '5pm'},
                {id: 18, name: '6pm'},
                {id: 19, name: '7pm'},
                {id: 20, name: '8pm'},
                {id: 21, name: '9pm'},
                {id: 22, name: '10pm'},
                {id: 23, name: '11pm'},
                {id: 24, name: '12am'},
            ]
        }
    },
    components: {
        DimensionsSettings
    },
    methods: {
        save: function () {
            try {
                this.formHasErrors = false

                Object.keys(this.defaults).forEach(f => {
                    if(this.$refs[f]){
                        if(!this.$refs[f].validate(true)) {
                            this.formHasErrors = true
                        } else {
                            this.settings.defaults[f] = this.defaults[f]
                        }
                    }
                })
                this.$emit('save')

            } catch (e) {
                console.log(e)
            }
        },
    },
    mounted: function () {
        Object.keys(this.settings.defaults).forEach(setting => {
            Vue.set(this.defaults, setting, this.settings.defaults[setting])
        })
    },
    computed: {
		...mapState('LiveRates', ['settings']), 
        form () {
            return { }
        },
    }
}
</script>

