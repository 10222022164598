<template>
    <div>
        <v-row no-gutters class="caption">
            <v-col cols="3" class="font-weight-bold" @click="expand(order)">
                <span v-if="show('order_summary', 'order_number_prefix')"><div v-for="(order_name, index) in merged.order_names(order)" :key="index">{{ order_name }}</div></span>
                <span v-else><div v-for="(order_number, index) in merged.order_numbers(order)" :key="index">{{ order_number }}</div></span>
            </v-col>
            <v-col v-if="show('order_summary', 'order_date')">
                {{ order.created_at | formatDate('DD/MM/YYYY') }}
            </v-col>
            <v-col v-if="show('order_summary', 'order_time')">
                {{ order.created_at | formatDate('hh:mm a') }}
            </v-col>
            <v-col v-if="show('order_summary', 'order_total')" class="text-right font-weight-bold pr-6">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-if="order.financial_status == 'pending'" x-small class="py-2 mr-2" color="error" v-bind="attrs" v-on="on">Pending</v-chip>    
                    </template>
                    <span>CAUTION: Payment is pending on this order</span>
                </v-tooltip>
                ${{ order.current_total_price | formatNumber(2) }}
            </v-col>
        </v-row>
        <v-row no-gutters class="caption">
            <v-col cols="3" v-if="show('customer', 'name')">{{ company }} {{ customer }}</v-col>
            <v-col cols="6" v-if="show('customer', 'address')">{{ street1 }} {{ street2 }} {{ city }} <span v-if="show('customer', 'country')">{{ country }}</span></v-col>
            <v-col cols="3" class="caption">
                <span class="font-weight-bold">Shipping Selected:</span> {{ order.shipping_lines[0] ? order.shipping_lines[0].title : 'Not specified' }} <span v-if="show('order_summary', 'shipping_price')">${{ order.shipping_lines[0] ? order.shipping_lines[0].discounted_price : 0  | formatNumber(2) }}</span>
            </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row no-gutters>
            <v-col :cols="showing_products ? 7 : 6" class="py-0 px-1 debug-border" :class="{ 'offset-1': !showing_products, 'pl-5' : showing_products }">
                <Items :order="order" v-on:toggle_products="toggle_products($event)"/> 
            </v-col>
            <v-col cols="5" class="py-0 pl-0 pr-3 debug-border">
                <Packages :order="order"/> 
                <Actions :order="order" class="pr-2" v-on:action="$emit('action', $event)" v-on:close="$emit('close')" v-on:expand="$emit('expand')"/> 
                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                    <Consignments v-if="order.consignment" :order="order"/> 
                </v-lazy>
                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                    <Rates v-if="!order.consignment || !order.consignment.shipment" :order="order"/> 
                </v-lazy>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Customer from '@/V2/components/Orders/TableView/Customer'
import Rates from '@/V2/components/Orders/Rates'
import Consignments from '@/V2/components/Orders/Consignments'
import Packages from '@/V2/components/Orders/TableView/Packages'
import Items from '@/V2/components/Orders/TableView/Items'
import Actions from '@/V2/components/Orders/Actions'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
            products_expanded: false,
            merged: mergeTools
        }
    },
    components: {
//        Customer,
        Rates,
        Consignments,
        Packages,
        Items,
        Actions
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			try {
				if(subsection) {
					return this.display_settings.table[section].subsections[subsection].status
				} else {
					return this.display_settings.table[section].status
				}
			} catch (e) {
				return true
			}
        },
        toggle_products(expanded) {
            this.products_expanded = expanded
        }
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
		}),
        showing_products() {
            if(this.products_expanded) {
                return true
            }
            try {
                return this.display_settings.table.products.subsections.open.status
            } catch (e) {
                return false
            }
        },
        showing_packages() {
            try {
                return this.display_settings.table.packages.subsections.open.status
            } catch (e) {
                return false
            }
        },
        company() {
            return this.order.shipping_address.company
        },
        customer() {
            return `${this.order.shipping_address.first_name} ${this.order.shipping_address.last_name}`
        },
        street1(){
            return this.order.shipping_address.address1
        },
        street2(){
            return this.order.shipping_address.address2
        },
        city() {
            return `${this.order.shipping_address.city} ${this.order.shipping_address.province_code} ${this.order.shipping_address.zip}`
        },
        country() {
            return this.order.shipping_address.country_code
        }
    }
}
</script>

<style scoped>
    .xdebug-border {
        border: 1px solid red
    }
    .xdebug-border-blue {
        border: 1px solid blue
    }
</style>