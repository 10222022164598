import store from "@/V2/store/index"
import Vue from 'vue'
import orderTools from "@/V2/helpers/orderTools";
import axios from 'axios'
import { errors } from '@/V2/integrations/eParcel/store/eparcel_errors'
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    create_consignments: async function (orders) {
        const create: Array<any> = []
        const update: Array<any> = []
        const requests: Array<any> = []
        store.commit('set_progress', { status: true, message: 'Creating shipments' }) 
        orders.forEach(order => {
            order.consignment.data = this.build_shipment({ order: order, carrier_id: order.consignment.service.carrier_id, service: order.consignment.service.code })
            if(this.get_shipment_id(order)) {
                if(!order.consignment.shipment.order_id) {
                    update.push(order)
                }
            } else {
                create.push(order)
            }
        })

        const request = this.submit_shipments(create, 'POST')
        requests.push(request)
        for(const order of update) {
            const request = this.submit_shipments([order], 'PUT', this.get_shipment_id(order))
            requests.push(request)
        }

        return Promise.all(requests).then( (values) => {
            const errors = values.find(element => element == true)
            store.commit('set_progress', { status: false, message: null }) 
            return !errors
        })
    },
    create_manifest: async function (orders, retries = 0) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        if(retries > 10) {
            store.commit('set_system_error_message', 'An unidentified error has occurred while manifesting your consignments. Several retries have been attempted automatically. <br><br>Please try again in a few minutes and if the issue continues to occur contact our support team via online chat for assistance.' )
            store.commit('set_progress', { status: false, message: null }) 
            return
        }
        store.commit('set_progress', { status: true, message: 'Preparing manifest' }) 
        let retry = false 
        const shipments: Array<any> = []
        const manifests = {}
        const credentials = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials

        try {
            orders.forEach(order => {
                if(order.consignment && order.consignment.shipment && order.consignment.shipment.order_id) {
                    manifests[order.consignment.shipment.order_id] = true
                } else {
                    shipments.push({ shipment_id: order.consignment.shipment.shipment_id })
                }
            })
    
        } catch (e) {
            console.log(e)
        }

        if(shipments.length) {
            const request = {
                shipments: shipments,
            }
            axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, { 
                request: request
            },
            {
                headers: { 
                    Authorization: token,
                    account_number: credentials.account_number,
                    api_key: credentials.api_key,
                    secret: credentials.secret,
                    method: 'PUT',
                    resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/orders`
                }
            })
            .then(response => {
                if(response.data.body.errors) {
                    for(const error of response.data.body.errors) {
                        const order = orders.find(o => {
                            return o.consignment.shipment.shipment_id == error.context.shipment_id
                        })
                        if(error.code == '44017') {
                            // Already manifested so update shipment
                            this.update_shipment(order)
                                
                            retry = true
                        } else {
                            console.log('init error', order, error.message)
                            store.dispatch('Orders/init_error', { order: order, type: 'label', error: this.nicer_message(error) }, { root: true })
                        }
                    }
                }
                if(response.data.body.order) {
                    const events: Array<any> = []
                    for(const order of orders) {
                        this.update_shipment(order)
                        events.push({
                            id: order.id,
                            type: 'CreateManifest',
                            data: orderTools.loggable_data(order)
                        })
                    }
                    orderTools.log(events)
                    this.print_manifest(response.data.body.order.order_id, credentials)
                }
                if(retry) {
                    store.commit('set_progress', { status: true, message: 'Updating manifested shipments' }) 
                    setTimeout( () => { 
                        this.create_manifest(orders, retries + 1) 
                    }, 1000); 
                } else {
                    store.commit('set_progress', { status: false, message: null }) 
                }
            })
        }
        for (const manifest_id of Object.keys(manifests)) {
            this.print_manifest(manifest_id, credentials)
        }

    },
    print_manifest: function (manifest_id, credentials) {
        return axios.post(`${process.env.VUE_APP_API_SERVER}/api/eparcel/summary.json`, {
            account_number: credentials.account_number,
            api_key: credentials.api_key,
            secret: credentials.secret,
            manifest_id: manifest_id
        }, {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters['Shopify/token']}`
            },
        }).then((response) => {
            if(response.data.url) {
                const labelWindow = window.open(response.data.url, '_blank');

                if(!labelWindow || labelWindow.closed || typeof labelWindow.closed=='undefined') 
                { 
                    store.commit('set_popup_blocked', response.data.url)
                }
                store.commit('set_progress', { status: false, message: null }) 
            }
        })

    },
    get_labels: async function (request_id, credentials) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/get`, {},
        {
            headers: { 
                Authorization: token,
                account_number: credentials.account_number,
                api_key: credentials.api_key,
                secret: credentials.secret,
                resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/labels/${request_id}`
            }
        })
    },
    create_labels: async function (orders) {
        store.commit('set_progress', { status: true, message: 'Generating labels' }) 
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        orders.forEach(order => {
            shipments.push({ shipment_id: order.consignment.shipment.shipment_id })
        })

        const printer_settings = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).settings.printer
        const credentials = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials
        
        const request = {
            wait_for_label_url: false,
            shipments: shipments,
            preferences: {
                type: "PRINT",
                groups: [
                    {
                        group: "Parcel Post",
                        layout: printer_settings.parcel_post.label,
                        branded: printer_settings.parcel_post.branded,
                        top_offset: printer_settings.parcel_post.top_offset,
                        left_offset: printer_settings.parcel_post.left_offset
                    },
                    {
                        group: "Express Post",
                        layout: printer_settings.express_post.label,
                        branded: printer_settings.express_post.branded,
                        top_offset: printer_settings.express_post.top_offset,
                        left_offset: printer_settings.express_post.left_offset
                    },
                    {
                        group: "International",
                        layout: printer_settings.international.label,
                        branded: true,
                        top_offset: printer_settings.international.top_offset,
                        left_offset: printer_settings.international.left_offset
                    },
                ]
            }
        }
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, { 
            request: request
        },
        {
            headers: { 
                Authorization: token,
                account_number: credentials.account_number,
                api_key: credentials.api_key,
                secret: credentials.secret,
                resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/labels`
            }
        })
        .then(async (response) => {
            let labels_are_pending = true
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const order = orders.find(o => {
                        return o.consignment.shipment.shipment_id == error.context.shipment_id
                    })
                    console.log('init error', order, error.message)
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: this.nicer_message(error) }, { root: true })
                }
                labels_are_pending = false
            } 

            while(labels_are_pending){
                await this.get_labels(response.data.body.labels[0].request_id, credentials).then(response => {
                    if(response.data.body.labels[0].status != 'PENDING') {
                        const labelWindow = window.open(response.data.body.labels[0].url, '_blank');

                        if(!labelWindow || labelWindow.closed || typeof labelWindow.closed=='undefined') 
                        { 
                            store.commit('set_popup_blocked', response.data.body.labels[0].url)
                        }
                        labels_are_pending = false
                    }
                })
            } 
            store.commit('set_progress', { status: false, message: null }) 
        })
    },


    get_shipment_index_from_error: function (error) {
        if(error.context) {
            if(error.context.shipment_index) {
                return error.context.shipment_index
            }
        }
        if(error.field) {
            const fields = error.field.split('.')
            const index = fields[0] ? fields[0].match(/\d+/) : null
            if(index) {
                return index[0]
            }
        }
    },
    update_shipment: async function (order) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const credentials = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/get`, {},
        {
            headers: { 
                Authorization: token,
                account_number: credentials.account_number,
                api_key: credentials.api_key,
                secret: credentials.secret,
                resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/shipments/${order.consignment.shipment.shipment_id}`
            }
        })
        .then(response => {
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const message = this.nicer_message(error)
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
            }
            if(response.data.body.shipments) {
                Vue.set(order.consignment, 'shipment', response.data.body.shipments[0])
                Vue.set(order, 'save_event', 'UpdateShipmentData')
                store.dispatch('Orders/save', order)
            }
        })
    },
    delete_shipments: async function (orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        const credentials = store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials

        for(const order of orders) {
            if(order.consignment && order.consignment.shipment) {
                shipments.push({
                    id: order.consignment.shipment.shipment_id,
                    carrier_id: order.consignment.service.carrier_id,
                })
                Vue.set(order, 'deleted_consignment', order.consignment)
                Vue.delete(order, 'consignment')
                Vue.set(order, 'save_event', 'DeleteConsignment')
                store.dispatch('Orders/save', order)
            }
        }
        while(shipments.length > 0) {
            const shipment = shipments.splice(0, 1)[0]
            const query =  encodeURI(`shipment_ids=${shipment['id']}`)
            axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, {},
            {
                headers: { 
                    Authorization: token,
                    account_number: credentials.account_number,
                    api_key: credentials.api_key,
                    secret: credentials.secret,
                    method: 'DELETE',
                    resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/shipments/${shipment['id']}`
                }
            })
        }

    },
    get_shipments: function (ids, carrier) {

    },
    submit_shipments: async function(orders, method, shipment_id = '') {
        const token = await getSessionToken(store.getters['Shopify/app']);
        if(orders.length == 0) return false
        store.commit('set_progress', { status: true, message: 'Sending shipments to Australia Post' }) 
        const shipments: Array<any> = []
        let hasErrors = false
        orders.forEach(order => {
            shipments.push(order.consignment.data)
        })

        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, { 
            request: (shipment_id ? shipments[0] : { shipments: shipments })
        },
        {
            headers: { 
                Authorization: token,
                account_number: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials.account_number,
                api_key: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials.api_key,
                secret: store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials.secret,
                method: method,
                resource: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/shipments` + (shipment_id ? `/${shipment_id}` : ''),
            }
        })
        .then(response => {
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const shipment_index = this.get_shipment_index_from_error(error)
                    const order = orders[shipment_index ?? 0]
                    if(!shipment_id) {
                        Vue.delete(order, 'consignment')
                    }
                    const message = this.nicer_message(error)
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
                hasErrors = true
            }

            if(response.data.body.shipments) {
                response.data.body.shipments.forEach( (shipment, idx) => {
                    const order = orders[idx]
                    Vue.set(orders[idx].consignment, 'shipment', shipment)
                    Vue.set(orders[idx], 'save_event', 'ShipmentCreated')
                    store.dispatch('Orders/save', orders[idx])
                })
            }
            if(response.data.body.shipment_id) {
                Vue.set(orders[0].consignment, 'shipment', response.data.body)
                Vue.set(orders[0], 'save_event', 'ShipmentUpdated')
                store.dispatch('Orders/save', orders[0])
            }
        }).catch((error) => {
            return true
        }).then(( ) => {
            return hasErrors
        });
    },
    build_shipment: function ({ order, carrier_id, service }) {
        const from = store.getters['User/address']
        const to = order.shipping_address
        this.set_order_options({ order, carrier_id })
        let options = {}
        const is_domestic = orderTools.is_domestic(order)
        if(is_domestic) {
            options = order.orderOptions[carrier_id].domestic
        } else {
            options = order.orderOptions[carrier_id].international
        }
        const features = this.build_features(order, carrier_id)
        const order_packages = orderTools.get_package(order)

        if(!is_domestic && order_packages.length > 1) {
            store.dispatch('Orders/init_error', { order: order, type: 'packages', error: 'eParcel does not support multiple packages in a single consignment for international orders.' }, { root: true }) 
            return
        }
        const items: Array<any> = []
        order_packages.forEach(p => {
            let weight = Number(p.weight || 0) + Number(p.materials || 0)
            if(weight == 0){
                weight = store.getters['Packages/defaults'].weight
                Vue.set(order, 'weight_forced', true) 
            } else {
                Vue.set(order, 'weight_forced', false) 
            }
            items.push(
                {
                    item_id: this.get_item_id(order, items.length),
                    weight: Number(Vue.filter('formatNumber')(weight, 3)),
                    length: p.dimensions[0] ? Number(Vue.filter('formatNumber')(p.dimensions[0], 1)) : 5,
                    width: p.dimensions[1] ? Number(Vue.filter('formatNumber')(p.dimensions[1], 1)) : 5,
                    height: p.dimensions[2] ? Number(Vue.filter('formatNumber')(p.dimensions[2], 1)) : 5,
                    product_id: service,
                    features: features,
                    contains_dangerous_goods: options['contains_dangerous_goods'],
                    transportable_by_air: options['contains_dangerous_goods'] ? options['transportable_by_air'] : null,
                    dangerous_goods_declaration: options['contains_dangerous_goods'] ? options['dangerous_goods_declaration'] : null,
                    allow_partial_delivery: options['allow_partial_delivery'],	
                    authority_to_leave: is_domestic ? (options['signature_options'] == 'authority_to_leave' ? true : false) : null,
                    safe_drop_enabled: is_domestic ? (options['signature_options'] == 'safe_drop_enabled' ? true : false) : null,
                    commercial_value: !is_domestic ? options['commercial_value'] : null,
                    international_parcel_sender_name: !is_domestic ? options['international_parcel_sender_name']: null,
                    export_declaration_number: !is_domestic ? options['export_declaration_number']: null,
                    import_reference_number:  !is_domestic ? options['import_reference_number']: null,
                    classification_type: !is_domestic ? options['classification_type'] : null,
                    description_of_other: !is_domestic ? (options['classification_type'] == 'OTHER' ? options['description_of_other'] : null) : null,
                    non_delivery_action: !is_domestic ? options['non_delivery_action'] : null,
                    certificate_number:  !is_domestic ? options['certificate_number']: null,
                    licence_number:  !is_domestic ? options['licence_number']: null,
                    comments:  !is_domestic ? options['comments']: null,
                    item_contents: this.item_contents(order, options)
                }
            )
        }) 

        const shipment = {
            from: {
                name: from.name || null,
                business_name: from.company || null,
                phone: from.phone ? from.phone.trim() : '',
                email: from.email || null,
                lines: orderTools.build_address_lines([
                    from.address1 || null,
                    from.address2 || null
                ]),
                suburb: from.suburb ? from.suburb.trim() : '',
                state: orderTools.abbreviated_state(from.state) || null,
                postcode: from.postcode ? from.postcode.trim() : '',
                country: from.country ? from.country.trim() : ''

            },
            to: {
                name: to.name || null,
                business_name: to.company || null,
                phone: to.phone ? to.phone.trim() : (order.customer && order.customer.phone ? order.customer.phone.trim() : ''),
                email: order.email || null,
                lines: orderTools.build_address_lines([
                    to.address1 || null,
                    to.address2 || null
                ]),
                suburb: to.city ? to.city.trim() : '',
                state: orderTools.abbreviated_state(to.province_code) || null,
                postcode: to.zip ? to.zip.trim() : '',
                country: to.country_code ? to.country_code.trim() : ''
            },
            shipment_references: [order.id.toString()],
            sender_references: orderTools.get_reference(order, 50),
            email_tracking_enabled: options['email_tracking'] && order.email ? true : false,
            goods_descriptions: options['goods_descriptions'],
            items: items,

        }
        return orderTools.removeEmpty(shipment)

    },
    get_item_id: function(order, item = 0) {
        try {
            return order.consignment.shipment.items[item].item_id
        } catch (e) {
            return null
        }
    },
    item_contents: function (order, options) {
        if(orderTools.is_domestic(order)) return null
        const contents: Array<any> = []
        const total_order_weight = orderTools.get_line_item_weights(order)
        if(options.description || options.market_value  || orderTools.count_fulfillable_line_items(order) > 20) {
            const value = options.market_value ? options.market_value : Number(orderTools.get_order_total(order)).toFixed(2)
            contents.push({
                description: (options.description ? options.description.substring(0, 40) : null) || 'Miscellaneous Goods',
                quantity: 1,
                value: value < 1 ? 1 : value,
                tariff_code: options.tariff_code,
                country_of_origin: options.country_of_origin,
                weight: Vue.filter('formatNumber')(orderTools.get_total_package_weight(order) / 1000, 3)
            })
        } else {
            order.line_items.forEach(item => {
                if(item.fulfillable_quantity && item.requires_shipping) {
                    if(item.price < 1) {
                        contents.push({
                            description: `${item.fulfillable_quantity}x${item.name}`.substring(0, 40),
                            quantity: 1,
                            value: orderTools.discounted_item_price(item) * item.fulfillable_quantity < 1 ? 1 : Number(orderTools.discounted_item_price(item) * item.fulfillable_quantity).toFixed(2),
                            tariff_code: item.hs_code ? item.hs_code : options.tariff_code,
                            country_of_origin: item.country_of_origin ? item.country_of_origin : options.country_of_origin,
                            weight: total_order_weight > 0 ? (!options.hide_weights ? Vue.filter('formatNumber')((item.grams * item.fulfillable_quantity) / 1000, 3) : null) : null
                        })
                    } else {
                        contents.push({
                            description: `${item.name}`.substring(0, 40),
                            quantity: item.fulfillable_quantity,
                            value: orderTools.discounted_item_price(item) < 1 ? 1 : orderTools.discounted_item_price(item),
                            tariff_code: item.hs_code ? item.hs_code : options.tariff_code,
                            country_of_origin: item.country_of_origin ? item.country_of_origin : options.country_of_origin,
                            weight: total_order_weight > 0 ? (!options.hide_weights ? Vue.filter('formatNumber')((item.grams * item.fulfillable_quantity) / 1000, 3) : null) : null
                        })
                    } 
                }
            })
        }
        return contents
    },
    build_features: function (order, carrier_id) {
        let options = {}
        if(orderTools.is_domestic(order)) {
            options = order.orderOptions[carrier_id].domestic
        } else {
            options = order.orderOptions[carrier_id].international
        }
        if(options['transit_cover'] == true && Number(options['transit_cover_amount']) >= 1) {
            const features = {
                TRANSIT_COVER: {
                    attributes: {
                        cover_amount: Number(options['transit_cover_amount'])
                    }
                }
            }
            if(Number(options['transit_cover_amount']) > 500 && orderTools.is_domestic(order)){
                order.orderOptions[carrier_id].domestic.delivery_options = 'signature_required'     
            }
            return features
        }
    },
    set_order_options: function (payload) {
        const carrier = store.getters[`Carriers/carrier`](payload.carrier_id)
        if(carrier) {
            if(!payload.order.orderOptions) {
                Vue.set(payload.order, 'orderOptions', {})
            }
            if(!payload.order.orderOptions[payload.carrier_id] || !payload.order.orderOptions[payload.carrier_id].modified) {
                if(orderTools.is_domestic(payload.order)) {
                    Vue.set(payload.order.orderOptions, payload.carrier_id, {
                        domestic: Object.assign({}, carrier.settings.domestic)
                    })
                    payload.order.orderOptions[payload.carrier_id].domestic['transit_cover_amount'] = Number(orderTools.get_order_total(payload.order) * (payload.order.orderOptions[payload.carrier_id].domestic.transit_cover_percentage / 100)).toFixed(2)
    
                } else {
                    Vue.set(payload.order.orderOptions, payload.carrier_id, {
                        international: Object.assign({}, carrier.settings.international)
                    })
                    payload.order.orderOptions[payload.carrier_id].international['transit_cover_amount'] = Number(orderTools.get_order_total(payload.order) * (payload.order.orderOptions[payload.carrier_id].international.transit_cover_percentage / 100)).toFixed(2)
                }
            }
        }
    },
    get_rate_request_body: function (payload) {
        if(payload.order && payload.order.shipping_address){
            const shipments: Array<any> = []
            try {
                const shipment = this.build_shipment({ order: payload.order, carrier_id: payload.carrier_id, service: null })
                if(shipment) shipments.push(shipment)

            } catch (e) {
                console.log(e)
            }
            return {
                shipments: shipments
            }
        }
    },
    get_shipment_id: function(order) {
        try {
            return order.consignment.shipment.shipment_id
        } catch (e) {
            return null
        }
    },
    nicer_message: function (error) {
        if(errors[error.code]) {
            return errors[error.code]
        }
        if(errors[error.message]) {
            return errors[error.message]
        }
        for( const k in errors ) {
            if(error.message.search(k) > -1){
                return errors[k]
            } 
        }
        return error.message
    },
    get_hidden_services: function (carrier) {
        return {
            'RPI8': true,
            'AIR8': true,
        }
    },
    get_shipment_status: function (status) {
        if(!status){
            return 0
        }
        switch(status) {
            case 'Created':
                return 'text-initiated'
            case 'Locked':
                return 'text-initiated'
            case 'Sealed':
                return 'text-initiated'
            case 'Initiated':
                return 'text-initiated'
            case 'In transit':
                return 'text-info'
            case 'Delivered':
                return 'text-success'
            case 'Awaiting collection':
                return 'text-danger'
            case 'Possible delay':
                return 'text-danger'
            case 'Unsuccessful pickup':
                return 'text-danger'
            case 'Article damaged':
                return 'text-danger'
            case 'Cancelled':
                return 'text-danger'
            case 'Held by courier':
                return 'text-danger'
            case 'Cannot be delivered':
                return 'text-danger'
            case 'Track items for detailed delivery information':
                return 'text-danger'
            default:
                return 'text-warning'
        }
    }

}


