<template>
    <v-card class="mt-4" outlined>
        <v-toolbar color="indigo" dark dense>
            <v-row class="flex-column flex-sm-row">
                <v-col cols="12" sm="6">
                    <v-toolbar-title class="pt-2">Active Product List</v-toolbar-title>
                </v-col>
                <v-col cols="12" sm="6" class="pb-2 text-right">
                    <v-btn small light color="primary" class="toolbar-button mx-2" @click="updateShowImages(!showImages)">
                        {{ showImages ? 'Hide' : 'Show' }} Images
                    </v-btn>
                    <v-btn small light color="primary" class="toolbar-button mx-2" @click="selected = filteredItems">
                        Select All ({{ filteredItems.length }})
                    </v-btn>
                    <v-btn small v-if="selected.length" light color="error" class="toolbar-button mr-2" @click="selected = []">
                        Deselect All ({{ selected.length }})
                    </v-btn>
                    <v-btn small v-if="selected.length" light class="toolbar-button mr-2" @click="edit_all = true">
                        Edit Selected ({{ selected.length }})
                    </v-btn>
                    <v-btn small :disabled="dirtyProducts.length === 0 && !editing" color="success" class="toolbar-button mr-4" @click="save">
                        Save Changes ({{ dirtyProducts.length ? (editing ? dirtyProducts.length + 1 : dirtyProducts.length) : (editing ? 1 : 0)}})
                    </v-btn>
                    <span v-if="lastUpdated && url" class="mt-3">
                        Last Synced {{ lastUpdated | formatDate('DD MMM YYYY') }}
                        <span v-if="up_to_date && modified_count != null"> (Up to date)</span>
                        <span v-else> ({{ modified_count }} product<span v-if="modified_count>1">s</span> modified)</span>
                        <v-btn v-if="!up_to_date" @click="refresh" icon><v-icon right dark>mdi-refresh</v-icon></v-btn>
                    </span>
                    <span v-if="!url" class="mt-2">
                        Syncing in progress
                    </span>
                </v-col>
            </v-row>
        </v-toolbar>        
        <v-card-title>
            <v-row no-gutters>
                <v-col cols="12" md="3" class="px-2 mb-2">
                    <v-text-field
                        v-model="searchTitle"
                        label="Filter by Title"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="mb-2">            
                    <v-row no-gutters>
                        <v-col cols="2" sm="4" class="text-right caption pt-2 pr-2 font-weight-bold">Length (cm):</v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="lengthMin"
                                label="Min"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="lengthMax"
                                label="Max"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="mb-2">
                    <v-row no-gutters>
                        <v-col cols="2" sm="4" class="text-right caption pt-2 pr-2 font-weight-bold">Width (cm):</v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="widthMin"
                                label="Min"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="widthMax"
                                label="Max"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" class="mb-2">
                    <v-row no-gutters>
                        <v-col cols="2" sm="4" class="text-right caption pt-2 pr-2 font-weight-bold">Height (cm):</v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="heightMin"
                                label="Min"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="4">
                            <v-text-field
                                v-model="heightMax"
                                label="Max"
                                outlined
                                dense
                                class="range"
                                hide-details
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :headers="computedHeaders"
            :items="filteredItems"
            :items-per-page="itemsPerPage"
            @update:items-per-page="updateItemsPerPage"
            :footer-props="{itemsPerPageOptions: [25,100,200,500]}"
            show-select
            dense
            fixed-header
            @click:row="expand"
        >
            <template v-slot:[`item.featuredImage`]="{ item }">
                <template v-if="item.featuredImage">
                    <div class="align-left image-container">
                        <v-img
                            v-if="item.featuredImage"
                            :src="item.featuredImage.url"
                            
                            max-width="50"
                            class="image-style"></v-img>
                    </div>
                </template>
                <div v-else style="width: 50px; height: 50px" class="ma-1">&nbsp;</div>
            </template>
            <template v-slot:[`item.length.value`]="{ item }">
                <div class="align-left my-1">
                    <v-text-field v-model="item.length.value" type="number" outlined dense hide-details="auto" @input="editing = true" @change="item.dirty = true; editing = false" style="max-width: 100px" reverse></v-text-field>
                </div>
            </template>
            <template v-slot:[`item.width.value`]="{ item }">
                <div class="align-left my-1">
                    <v-text-field v-model="item.width.value" type="number" outlined dense hide-details="auto" @input="editing = true" @change="item.dirty = true; editing = false" style="max-width: 100px" reverse></v-text-field>
                </div>
            </template>
            <template v-slot:[`item.height.value`]="{ item }">
                <div class="align-left my-1">
                    <v-text-field v-model="item.height.value" type="number" outlined dense hide-details="auto" @input="editing = true" @change="item.dirty = true; editing = false" style="max-width: 100px" reverse></v-text-field>
                </div>
            </template>
        </v-data-table>
        <WarningDialog ref="warning"/>
        <v-dialog v-model="edit_all" max-width="300">
            <v-card ref="form">
                <v-toolbar dark color="primary" dense flat>
                    <v-toolbar-title class="white--text">Bulk edit selected products</v-toolbar-title>
                </v-toolbar>
                <v-container>
                    <v-text-field ref="length" label="Length" v-model="bulk.length" outlined dense :rules="[rules.required, rules.numeric]"></v-text-field>
                    <v-text-field ref="width" label="Width" v-model="bulk.width" outlined dense :rules="[rules.required, rules.numeric]"></v-text-field>
                    <v-text-field ref="height" label="Height" v-model="bulk.height" outlined dense :rules="[rules.required, rules.numeric]"></v-text-field>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="bulkEdit">Submit</v-btn>
                    <v-btn @click="edit_all = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import WarningDialog from '@/V2/components/Utilities/WarningDialog.vue'
import { getSessionToken } from '@shopify/app-bridge-utils';
import {graphql} from "@/V2/helpers/shopify";

export default {
    data: function () {
        return {
            itemsPerPage: 25,
            product_count: null,
            modified_count: null,
            up_to_date: true,
            url: '',
            lastUpdated: null,
            product_list: {},
            products: [],
            headers: [
                { text: 'Image', sortable: false, value: 'featuredImage' },
                { text: 'Title', align: 'start', sortable: true, value: 'title' },
                { text: 'Length (cm)', align: 'start', sortable: true, value: 'length.value' },
                { text: 'Width (cm)', align: 'start', sortable: true, value: 'width.value' },
                { text: 'Height (cm)', align: 'start', sortable: true, value: 'height.value' },
            ], 
            selected: [],
            edit_all: false,
            bulk: {
                length: null,
                width: null,
                height: null
            },
            dirty: false,
            rules: {
                numeric: value => {
                    const pattern = /^\d*\.?\d+$/;
                    if (pattern.test(value)) return true;
                    return "Input must be numeric only!";
                },
                required: value => !!value || 'A numeric value is required.',
            },
            jwt: null,
            jwt_result: null,
            keys: [
                'length',
                'width',
                'height'
            ],
            searchTitle: '',
            lengthMin: '',
            lengthMax: '',
            widthMin: '',
            widthMax: '',
            heightMin: '',
            heightMax: '',
            showImages: false,
            editing: false
        }
    },
    components: {
        WarningDialog
    },
    methods: {
        expand: function () {

        },
        save: async function () {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            const query = `
                mutation MetafieldsSet($metafields: [MetafieldsSetInput!]!) {
                    metafieldsSet(metafields: $metafields) {
                        userErrors {
                            field
                            message
                            code
                        }
                    }
                }`
            let variables = { metafields: [] }
            this.dirtyProducts.map(async(p) => {
                this.keys.forEach(k => {
                    variables.metafields.push({
                        ownerId: p.id,
                        namespace: `${this.appId}:shipping`,
                        key: k,
                        value: p[k].value,
                        type: 'number_decimal'
                    })
                })

            })
            while(variables.metafields.length > 0) {
				const metafields = variables.metafields.splice(0, 25)
                const response = await graphql({
                    data: {
                        graphql: {
                            query: query,
                            variables: { metafields: metafields }
                        }
                    }
                })
                if(!response.errors) {
                    metafields.forEach(m => {
                        let product = this.products.find(p => p.id === m.ownerId)
                        if(product) {
                            product.dirty = false
                        }
                    })
                    console.log('all good')
                }
                console.log(response.data)
            }
            this.editing = false

        },
        bulkEdit: function () {
            let hasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.form[f]) hasErrors = true
                this.$refs[f].validate(true)
            })
            if(!hasErrors) {
                this.selected.forEach(s => {
                    s.length.value = this.bulk.length
                    s.width.value = this.bulk.width
                    s.height.value = this.bulk.height
                    s.dirty = true
                })
                this.dirty = true
                this.edit_all = false
            }            
        },
        refresh: async function () {
            const count = await this.getProductCount()
            if(count > 0) {
                const estimate = Math.ceil(count / 5000)

                await this.$refs.warning.open('Warning', `Based on the number of products in your store resyncing product data will take approximately ${estimate} - ${estimate + 1} minutes. <br><br>This update will occur in the background so you can continue working in other areas of the app but won't be able to update any product dimensions.`, 'Do you wish to continue?', { color: 'orange darken-1', width: 400 }).then((confirm) => {
                    if(confirm) {
                        this.checkIfDirty()
                    }
                })
            }
        },
        checkIfDirty: async function () {
            if(this.dirtyProducts.length > 0) {
                await this.$refs.warning.open('Warning', `You currently have unsaved changes. Resyncing will lose those changes.`, 'Do you wish to continue?', { color: 'orange darken-1', width: 400 }).then((confirm) => {
                    if(confirm) {
                        this.updateAllProducts()
                    }
                })
            } else {
                this.updateAllProducts()
            }

        },
        getProductsInfo: async function () {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            return axios.get(`${process.env.VUE_APP_API_SERVER}/api/shopify/productsFile.json`, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                },
            }).then(response => {
                return response
            })

        },
        getProductData: function () {
            axios.get(this.url).then(response => {
                try {
                    this.lastUpdated = new Date(response.headers['last-modified']).toISOString()
                    this.getProductCount(this.lastUpdated)
                    const product_lines = this.parseFile(response.data)
                    product_lines.forEach(ln => {
                        if(ln.__parentId) {
                            if(ln.__parentId === 'gid://shopify/Product/7498397679839'){
                                console.log(ln)
                            } 
                            if(!this.product_list[ln.__parentId]){
                                Vue.set(this.product_list, ln.__parentId, {})
                            }
                            Vue.set(this.product_list[ln.__parentId], ln.key, {id: ln.id, value: ln.value})
                        } else {
                            Vue.set(this.product_list, ln.id, ln)
                            Vue.set(this.product_list[ln.id], 'dirty', false)
                        }
                    })
                    Object.keys(this.product_list).forEach(id => {
                        if(id === 'gid://shopify/Product/7498397679839'){
                                console.log(this.product_list[id])
                            } 
                        if(!this.product_list[id].length) {
                            Vue.set(this.product_list[id], 'length', {id: null, value: null})
                        }
                        if(!this.product_list[id].width) {
                            Vue.set(this.product_list[id], 'width', {id: null, value: null})
                        }
                        if(!this.product_list[id].height) {
                            Vue.set(this.product_list[id], 'height', {id: null, value: null})
                        }
                        this.products.push(this.product_list[id])
                    })

                } catch (e) {
                    console.log(e)
                }
            })
        },
        parseFile: function (content) {
            const lines = content.split(/\r?\n/);
            const results = [];
            for (const line of lines) {
                if (line) {
                    try {
                        const l = JSON.parse(line)

                        results.push(JSON.parse(line));
                    } catch (err) {
                        console.error("Error parsing a line:", err);
                    }
                }
            }
            console.log(results)
            return results;
        },
        checkProgress: async function () {
            const response = await this.getProductsInfo()
            try {
                if(response.data.products && response.data.products.url != this.url) {
                    this.url = response.data.products.url
                    this.getProductData()
                } else {
                    setTimeout( () => { this.checkProgress() }, 10000); 
                }

            } catch (e) {
                console.log(e)
            }
        },
        updateAllProducts: async function () {
            this.url = null
            this.products = []
            this.selected = []
            this.dirty = false
            axios.get(`${process.env.VUE_APP_API_SERVER}/api/shopify/enableDimensions.json`, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$store.getters['Shopify/token']}`
                },
            }).then(() => {
                setTimeout( () => { this.checkProgress() }, 10000); 
            })
        },
        getProductCount: async function (since=undefined) {
            const token = await getSessionToken(this.$store.getters['Shopify/app']);
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/shopify`, { 
                method: 'GET', 
                resource: 'products/count.json',
                params: `status=active${(since ? '&updated_at_min=' + new Date(this.lastUpdated).toISOString() : '')}`,
            },
            {
                headers: { 
                    'Authorization': token
                }
            })
            .then(response => {
                if(since) {
                    this.up_to_date = response.data.result.count > 0 ? false : true
                    this.modified_count = response.data.result.count
                } else {
                    this.product_count = response.data.result.count
                }
                return response.data.result.count
            })
        },
        updateItemsPerPage(val) {
            this.itemsPerPage = val;
            localStorage.setItem('liveRates.Dimensions.itemsPerPage', val);
        },
        updateShowImages(val) {
            this.showImages = val;
            localStorage.setItem('liveRates.Dimensions.showImages', val);
        },
        loadSettings() {
            const itemsPerPage = localStorage.getItem('liveRates.Dimensions.itemsPerPage');
            if (itemsPerPage) {
                this.itemsPerPage = parseInt(itemsPerPage, 10);
            }
            const showImages = localStorage.getItem('liveRates.Dimensions.showImages');
            if (showImages) {
                this.showImages = JSON.parse(showImages)
            }
        }    
    },
    created() {
        this.loadSettings();
    },
    mounted: function () {
        this.getProductCount()
        this.checkProgress()
    },
    computed: {
        ...mapState('LiveRates', ['settings']), 
        appId: function () {
          return process.env.VUE_APP_ID  
        }, 
        form () {
            return {
                length: this.bulk.length,
                width: this.bulk.width,
                height: this.bulk.height
            }
        },
        dirtyProducts () {
            return this.products.filter(item => item.dirty === true)
        },
        computedHeaders() {
            return this.headers.filter(header => header.value !== 'featuredImage' || this.showImages);
        },
        filteredItems() {
            return this.products.filter(item => {
                return (!this.searchTitle || item.title.toLowerCase().includes(this.searchTitle.toLowerCase()))  &&
                    (!this.lengthMin || Number(item.length.value) >= this.lengthMin) &&
                    (!this.widthMin  || Number(item.width.value)  >= this.widthMin)  &&
                    (!this.heightMin || Number(item.height.value) >= this.heightMin) &&
                    (!this.lengthMax || Number(item.length.value) <= this.lengthMax) &&
                    (!this.widthMax  || Number(item.width.value)  <= this.widthMax)  &&
                    (!this.heightMax || Number(item.height.value) <= this.heightMax);
                });
            }        
        }
    }
</script>

<style scoped>
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}
.image-container {
  max-height: 50px;
  width: auto;
}
.range {
  width: 95%;
}
.full-height {
    height: 100%;
}
.toolbar-button {
    min-height: 36px;
    border-radius: 4px;
}
</style>
