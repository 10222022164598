// Carriers
import Vue from 'vue'
import axios from 'axios'
import MyPost from '@/V2/integrations/MyPost/store/mypost'
import eParcel from '@/V2/integrations/eParcel/store/eparcel'
import ParcelContract from '@/V2/integrations/ParcelContract/store/parcelcontract'
import orderTools from "@/V2/helpers/orderTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
	namespaced: true,
	modules: {
		MyPost,
		eParcel,
		ParcelContract
	},
	state: {
		available_carriers: [
			'MyPost',
			'eParcel',
			'ParcelContract',
		],
		carriers: {},
		validated_carriers: 0
	},
	mutations: {
		remove: function (state, payload) {
			Vue.delete(state.carriers, payload.carrier_id)
		},
		init: function (state, payload) {
			payload.credentials = payload.credentials ? payload.credentials : {}
			payload.data = payload.data ? payload.data : {}
			payload.products = payload.products ? payload.products : {}
			payload.settings = payload.settings ? payload.settings : {}
			payload.packages = payload.packages ? payload.packages : {}
			Vue.set(state.carriers, payload.carrier_id, payload)
		},
		update: function (state, payload) {
			Vue.set(state.carriers[payload.carrier_id], payload.key, payload.value)
		},
	},
	getters: {
		available_carriers: (state, getters, rootState, rootGetters) => {
			let beta_carriers = []
			const beta_tester = rootGetters['Shopify/beta_tester']
			if(beta_tester) {
				beta_carriers = beta_tester.split('|')
			}
			const available = state.available_carriers.filter(carrier => {
				if(!state[carrier].beta) {
					return carrier
				} else if (beta_carriers.find(c => c == carrier)) {
					return carrier
				}
			});
			return available
		},
		carriers: state => {
			return state.carriers
        },
        carrier: (state) => (id) => {
			return state.carriers[id]
        },
        carrier_brand: (state) => (id) => {
			return state.carriers[id].carrier
        },
        carrier_name: (state) => (id) => {
			return state.carriers[id].name
        },
		credentials: (state) => (id) => {
			return state.carriers[id].credentials
		},
		settings: (state) => (id) => {
			return state.carriers[id].settings
		},
		validated_carriers: state => {
			return state.validated_carriers
		},
		carrier_list: state => {
			const arr: Array<any> = []
			Object.keys(state.carriers).forEach( (carrier) => {
				arr.push({
					id: state.carriers[carrier].carrier_id,
					type: state.carriers[carrier].carrier,
					name: state.carriers[carrier].name,
					description: `${state.carriers[carrier].carrier} ${state.carriers[carrier].name ? state.carriers[carrier].name : ''}` 
				})
			})
			return arr
		},
		services_list: (state) => (carrier_id, domestic) => {
            const services: Array<any> = []
			const carrier = state.carriers[carrier_id] 
			if(carrier) {
				for(const service in carrier.products){
					if(!carrier.settings.hidden || !carrier.settings.hidden.find( id => id === service)){
						if(carrier.products[service].domestic === domestic) {
							services.push({
								id: carrier.products[service].id,
								name: carrier.products[service].official_name
							})
						}
					}
				}
			}
			return services
		},
		grouped_services_list: (state) => (carrier_id, domestic) => {
            const services: Array<any> = []
			const carrier = state.carriers[carrier_id] 
			if(carrier) {
				for(const group in carrier.grouped_products){

					if(group && carrier.grouped_products[group].domestic === domestic) {
						services.push({
							id: carrier.grouped_products[group].id,
							name: carrier.grouped_products[group].description,
							services: carrier.grouped_products[group].services
						})
					}
				}
			}
			return services
		}
	},
	actions: {

		init: async function ({ dispatch, rootGetters }) {
			await dispatch('list')
			await dispatch('init_each_carrier')
			return true
		},
		init_each_carrier: async function ({ state, dispatch, commit }) {
			state.validated_carriers = 0
			for (const carrier in state.carriers) {
				const carrier_type = carrier.split('|')[0]
				try {
					await dispatch(`Carriers/${carrier_type}/init`, state.carriers[carrier], { root: true }).then(response => {
						commit('update', {
							carrier_id: carrier, 
							key: 'data',
							value: response.data ? response.data : {}, 
						} )
						commit('update', {
							carrier_id: carrier,
							key: 'products', 
							value: response.products ? response.products : {},
						} )
						commit('update', {
							carrier_id: carrier,
							key: 'grouped_products', 
							value: response.grouped_products ? response.grouped_products : {},
						} )
						commit('update', {
							carrier_id: carrier,
							key: 'packages', 
							value: response.packages ? response.packages : {},
						} )
					})
	
				} catch (e) {
					console.log(e)
				}
				state.validated_carriers++
			}
		},
		validate_sender_address: function({ state, dispatch }) {
			for (const carrier in state.carriers) {
				const carrier_type = carrier.split('|')[0]
				dispatch(`Carriers/${carrier_type}/validate_sender_address`, state.carriers[carrier], { root: true })
			}
		},
		add: async function({ commit, dispatch, rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/admin/add`, payload,
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				if(response.data.ack === "Success"){
					commit('init', payload)
					dispatch('Quotes/build_quotes_required_queue', {}, { root: true} )
					return true
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'carriers/add', data: err})
			})

		},
		save: async function({ state, rootGetters }, carrier_id) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/admin/add`, state.carriers[carrier_id],
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				orderTools.log([{
					id: 'Carriers',
					type: 'Save',
					data: { carrier: state.carriers[carrier_id], response: response.data }
				}])
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'carriers/save', data: err})
			})

		},
		remove: async function({ commit, rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/admin/remove`, payload,
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				if(response.data.ack === "Success"){
					commit('remove', payload)
					return true
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'carriers/remove', data: err})
			})

		},
		list: async function({ commit, dispatch, rootGetters }) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/admin/list`, {},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				if(response.data.carriers){
					for(const carrier of response.data.carriers) {
						dispatch(`Carriers/${carrier.carrier}/update`, carrier, { root: true }).then(response => {
							commit('init', response)
						})
					}
				}
				return (response.data)
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'carriers/list', data: err})
			})

		},
	}
}
