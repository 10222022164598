// Shopify
import createApp from '@shopify/app-bridge';
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        initialised: false,
        shop: '',
        token: '',
		host: '',
        billing: false,
		beta_tester: '',
		created: null,
		version: null,
		xxx: 'xxx'
    },
    mutations: {
		setApp: function (state, app)  {
			Vue.set(state, 'app', app)
		},
        setInitialised: function (state, payload) {
            state.initialised = payload.initialised
		},
		setCredentials: function (state, payload) {
			state.shop       = payload.shop
			state.host       = payload.host
			state.token      = payload.token
			state.beta_tester = payload.beta_tester
			state.created = payload.created
			state.version = payload.version
			localStorage.setItem('token', payload.token)
		},
    },
	getters: {
		app: state => {
			return state.app
		},
		token: state => state.token || localStorage.getItem('token'),
		shop: state => state.shop,
		host: state => state.host,
		beta_tester: state => state.beta_tester || '',
	},
    actions: {
		initAppBridge: async function({ state, commit, dispatch }, host) {
			try{ 
				const app = await createApp({
					apiKey: process.env.VUE_APP_SHOPIFY_APP_KEY || '',
					host: host,
					forceRedirect: true
				})
				commit('setApp', app)
			} catch(e) {}
			if(window.top !== window.self){
				commit('setInitialised', {initialised: true})
			}
			return state.app
		},

    },
    modules: {
    }
}
