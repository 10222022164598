<template>
    <div class="mt-n6">
        <v-row>
            <v-col cols="12" xl="8">
                <v-card>
                    <v-toolbar flat>
                        <v-toolbar-title>Account Preferences</v-toolbar-title>
                        <template v-slot:extension>
                            <v-tabs  show-arrows v-model="currentItem" slider-color="white">
                                <v-tab href="#tab-0">Sender Address</v-tab>
                                <v-tab href="#tab-1">Label Options</v-tab>
                                <v-tab href="#tab-2">Fulfillment Options</v-tab>
                                <v-tab href="#tab-3">Invoice Options</v-tab>
                                <v-tab href="#tab-4">Picklist Options</v-tab>
                                <v-tab href="#tab-5">Import Options</v-tab>
                                <!--<v-tab href="#tab-6">Merge Options</v-tab>-->
                            </v-tabs>
                        </template>
                    </v-toolbar>

<!-- Sender/Return Address  -->
                    <v-tabs-items v-model="currentItem">
                        <v-tab-item value="tab-0" class="pa-2">
                            <Instructions id="9258420" />
                            <v-card class="mt-4">
                                <v-card-title>
                                    Edit your Sender / Return Address
                                </v-card-title>
                                <v-alert v-if="system_error.length" text outlined dense icon="mdi-alert" type="error" class="ma-2" v-html="system_error[0].message"></v-alert>
                                <div class="pa-5">
                                    <v-text-field dense outlined label="Name" v-model="address.name" @change="save"></v-text-field>
                                    <v-text-field dense outlined label="Company" v-model="address.company" @change="save"></v-text-field>
                                    <v-text-field dense outlined :label="address.country == 'AU' ? 'ABN' : 'Tax ID'" v-model="address.tax_id" @change="save"></v-text-field>
                                    <v-text-field dense outlined label="Address (line 1)" v-model="address.address1" @change="save"></v-text-field>
                                    <v-text-field dense outlined label="Address (line 2)" v-model="address.address2" @change="save"></v-text-field>
                                    <v-text-field dense outlined label="City / Suburb / Town" v-model="address.suburb" @change="save"></v-text-field>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field dense outlined label="State / Province" v-model="address.state" @change="save"></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field dense outlined label="Postal / Zip Code" v-model="address.postcode" @change="save"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-select
                                        v-model="address.country"
                                        :items="sorted_countries"
                                        item-text="name" item-value="id"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined dense hide-details
                                        label="Select a country" placeholder="Select a country"
                                        class="mb-4"
                                        @change="save"
                                    >
                                    </v-select>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field dense outlined label="Telephone" v-model="address.phone" @change="save"></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field dense outlined label="Mobile" v-model="address.mobile" @change="save"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-text-field dense outlined label="Email" v-model="address.email" @change="save"></v-text-field>
                                </div>
                            </v-card>
                        </v-tab-item>

<!-- Label Options  -->
                        <v-tab-item value="tab-1" class="pa-2">
                            <Instructions id="9258437" />
                            <v-card class="mt-4">
                                <v-card-title>
                                    Sender Reference Printed on Label
                                </v-card-title>
                                <div class="pa-5">
                                    <v-select
                                        v-model="label_options.preference_1"
                                        :items="label_preferences"
                                        item-text="name" item-value="id"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined dense hide-details
                                        label="Preference 1" placeholder="Select an option"
                                        class="mb-4"
                                        @change="save"
                                    >
                                    </v-select>
                                </div>
                                <div class="pa-5">
                                    <v-select
                                        v-model="label_options.preference_2"
                                        :items="label_preferences"
                                        item-text="name" item-value="id"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined dense hide-details
                                        label="Preference 2" placeholder="Select an option"
                                        class="mb-4"
                                        @change="save"
                                    >
                                    </v-select>
                                </div>
                                <div class="pa-5">
                                    <v-select
                                        v-model="label_options.preference_3"
                                        :items="label_preferences"
                                        item-text="name" item-value="id"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined dense hide-details
                                        label="Preference 3" placeholder="Select an option"
                                        class="mb-4"
                                        @change="save"
                                    >
                                    </v-select>
                                </div>
                            </v-card>
                        </v-tab-item>

<!-- Fulfillment Options  -->
                        <v-tab-item value="tab-2" class="pa-2">
                            <v-card class="mt-4">
                                <Instructions id="9258439" />
                                <v-card-title>
                                    Edit your Fulfillment Options
                                </v-card-title>
                                <div class="pa-5">
                                    <v-select
                                        v-model="fulfillment_options.notify_customer"
                                        :items="[{id: true, name: 'Enabled'}, {id: false, name: 'Disabled'}]"
                                        item-text="name" item-value="id"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined dense hide-details
                                        label="Notify customer of shipment" placeholder="Notify customer of shipment"
                                        class="mb-4"
                                        @change="save"
                                    >
                                    </v-select>
                                </div>
                            </v-card>
                        </v-tab-item>

<!-- Invoice Options  -->
                        <v-tab-item value="tab-3" class="pa-2">
                            <v-card class="mt-4">
                                <Instructions id="9258444" />
                                <v-card-title>
                                    Store Logo
                                </v-card-title>
                                <v-row class="px-5">
                                    <v-col cols="12" class="mb-3">
                                        <v-text-field dense outlined hide-details label="Logo URL" v-model="invoice_options.logo.url" @change="save"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-slider  label="Scale Logo" max="100" min="1"  thumb-label="always" thumb-size="24" v-model="invoice_options.logo.scale" @change="save"></v-slider>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5">
                                    <v-col>
                                        <v-responsive v-if="invoice_options.logo.url">
                                            <v-img
                                                contain
                                                :src="invoice_options.logo.url"
                                                :max-width="invoice_options.logo.max_width"
                                                :max-height="invoice_options.logo.max_height"
                                                @load="get_size"
                                                >
                                            </v-img>
                                        </v-responsive>
                                    </v-col>
                                </v-row>
                                <v-card-title>
                                    Options Available
                                </v-card-title>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Show Item Images</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="invoice_options.images.show" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5">
                                    <v-col cols="8">
                                        <v-textarea outlined hide-details label="Thank you message" v-model="invoice_options.other.message" @change="save"></v-textarea>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-tab-item>

<!-- Picklist Options  -->
                        <v-tab-item value="tab-4" class="pa-2">
                            <Instructions id="9258442" />
                            <v-card class="mt-4">
                                <v-card-title>
                                    Options Available
                                </v-card-title>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Show Item Images</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="picklist_options.images.show" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Page Break between each order</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="picklist_options.page_breaks" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Show Items Already Fulfilled</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="picklist_options.fulfilled" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-tab-item>
<!-- Import Options  -->
                        <v-tab-item value="tab-5" class="pa-2">
                            <v-card class="mt-4">
                                <v-card-title>
                                    Options Available
                                </v-card-title>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Import Orders with a Pending Payment Status</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="import_options.pending" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5">
                                    <v-col>
                                        <v-alert text outlined dense icon="mdi-alert" type="warning" class="ma-2">
                                            <span class="font-weight-bold">WARNING:</span> Enabling this option will allow any order with a payment status of "Pending" to be displayed in your Dashboard. <span class="font-weight-bold text-decoration-underline">This will include all unpaid orders</span>. This feature is normally only used by merchants that offer credit facilities to wholesale customers.
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
<!-- Merge Options  -->
                        <v-tab-item value="tab-6" class="pa-2">
                            <v-card class="mt-4">
                                <v-card-title>
                                    Options Available
                                </v-card-title>
                                <v-row class="px-5">
                                    <v-col cols="6" class="pr-2">Show all line item products for each mergeable order</v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="merge_options.show_products" color="success" @click="save" class="mt-0 pt-0"></v-switch>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import { countries } from '@/V2/helpers/countries'
import Instructions from '@/V2/views/Instructions'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
    data: function () {
        return {
            currentItem: 0,
            countries: countries,
            width: 200,
            height: 100,
            label_preferences: [
                {id: 'order_number', name: 'Order Number'},
                {id: 'sku_list', name: 'SKU List'},
                {id: 'sku_list_with_quantity', name: 'SKU List with Quantity'},
                {id: 'product_title', name: 'Product Title'},
                {id: 'package', name: 'Package'},
                {id: 'order_notes', name: 'Order Notes'},
                {id: 'nr', name: 'Not Required'},
            ]
        }
    },
	components: {
        Instructions
    },
    watch: {
    },
    methods: {
        get_size: function (url) {
            var vue = this
            let image = new Image();
            image.onload = function () {
                vue.width = this.width
                vue.height = this.height
                vue.invoice_options.logo.max_width = this.width * (vue.invoice_options.logo.scale / 100)
                vue.invoice_options.logo.max_height = this.height * (vue.invoice_options.logo.scale / 100)
            };
            image.src = url
        },
        save: function () {
            this.invoice_options.logo.max_width = this.width * (this.invoice_options.logo.scale / 100)
            this.invoice_options.logo.max_height = this.height * (this.invoice_options.logo.scale / 100)
            this.$store.dispatch('User/save')
            this.$store.dispatch('reset_system_errors', 'sender_address')
            this.$store.dispatch('Carriers/validate_sender_address')

        }
    },
	computed : {
        ...mapState('User', ['address', 'label_options', 'fulfillment_options', 'invoice_options', 'picklist_options', 'import_options', 'merge_options']),
        ...mapGetters({
            system_error: 'system_error'
        }),
        sorted_countries () {
            const sortedCountries = this.countries.slice().sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
                return 0;
            });
            return sortedCountries
        }

	}
}
</script>

