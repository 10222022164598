<template>
    <v-card class="pa-5 mb-2" style="font-size: 12px">
        <v-row >
            <v-col cols="4">
                <v-row>
                    <v-col class="h3 pt-0 mb-0">
                        Picklist
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Summary :order="order"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" class="pt-4 mt-5">
                <span class="h5">Billing Address:</span>
                <BillingAddress :order="order" class="mt-3"/>
            </v-col>
            <v-col cols="4" class="pt-4 mt-5">
                <span class="h5">Shipping Address:</span>
                <ShippingAddress :order="order" class="mt-3"/>
            </v-col>
        </v-row>
        <Items :order="order"/>
        <v-row>
            <v-col cols="7" class="pt-5">
                <div v-if="order.note" class="mt-5">
                    <span class="h5">Order Notes:</span>
                    <div class="mt-5">{{ order.note }}</div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Summary from '@/V2/components/Templates/invoice/Generic/summary'
import BillingAddress from '@/V2/components/Templates/invoice/Generic/billingAddress'
import ShippingAddress from '@/V2/components/Templates/invoice/Generic/shippingAddress'
import Items from '@/V2/components/Templates/picklist/Generic/items'

export default {
    props: ['order'],
	components: {
        Summary,
        BillingAddress,
        ShippingAddress,
        Items,
    },
    computed : {
        ...mapState('User', ['address', 'invoice_options']),
    }
}
</script>

<style>
    hr.thick {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 2px #333;
    }
    hr.thin {
        display: block; 
        height: 1px; 
        background: transparent; 
        width: 100%; 
        border: none; 
        border-top: solid 1px #333;
    }
</style>