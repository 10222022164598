<template>
    <div ref="childComponent">
        <div class="packages px-2 pb-2"> 
            <v-row v-if="layout == 'table'" no-gutters class="pb-4 pt-2">
                <v-col class="font-weight-bold">Package Settings</v-col>
                <v-col class="text-right">
                    <v-btn color="grey darken-1" x-small icon @click="$emit('close')"><v-icon color="grey">mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
            <template v-if="!order.packages || !order.packages.length">
                <v-row v-if="show('packages', 'list')">
                    <v-col class="pb-1 pt-1">
                        <v-select
                            v-model="selected"
                            :items="packages_list.items"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ bottom: true, offsetY: true }"
                            outlined 
                            dense
                            hide-details
                            label="Selected Package"
                            placeholder="Select a package"
                            class="mb-0 caption"
                            @change="select_single"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pr-1">{{ item.name }}</span>
                                <span v-if="item.id !== 'custom' && (item.dimensions[0] || item.dimensions[1] || item.dimensions[2])">
                                    (<span v-if="item.dimensions[0]">{{ item.dimensions[0] }}</span> <span v-if="item.dimensions[0]">x {{ item.dimensions[1] }}</span> <span v-if="item.dimensions[0]"><span v-if="item.dimensions[2]">x</span> {{ item.dimensions[2] }}</span>)
                                </span> 
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row v-if="show('packages', 'dimensions')">
                    <v-col cols="4" class="py-1">
                            <v-text-field outlined dense reverse hide-details label="Length (cm)" v-model="dimensions[0]" @change="save_dimensions" class="py-0">
                                <template v-slot:label>
                                    <span class="font-13">Length (cm)</span>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-1">
                            <v-text-field outlined dense reverse hide-details label="Width (cm)" v-model="dimensions[1]" @change="save_dimensions">
                                <template v-slot:label>
                                    <span class="font-13">Width (cm)</span>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-1">
                            <v-text-field outlined dense reverse hide-details label="Height (cm)" v-model="dimensions[2]" @change="save_dimensions">
                                <template v-slot:label>
                                    <span class="font-13">Height (cm)</span>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col v-if="selected == 'custom' && (!(dimensions[0] > 0) || !(dimensions[1] > 0) || !(dimensions[2] > 0))" cols="12" class="pb-0 mb-2">
                        <v-alert dense type="warning" icon="mdi-alert" class="mb-0">A value must be entered for each dimension</v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="show('packages', 'item_weight')" cols="5" class="py-1">
                            <v-text-field outlined dense reverse hide-details label="Total (kg)" v-model.number="weight" @change="save_weight"></v-text-field>
                    </v-col>
                    <v-col v-if="show('packages', 'item_weight') && show('packages', 'packaging_weight')" class="text-center pt-2">+</v-col>
                    <v-col v-if="show('packages', 'packaging_weight')" cols="5" class="py-1">
                            <v-text-field outlined dense reverse hide-details label="Materials (kg)" v-model.number="materials" @change="save_weight"></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="Number(weight) + Number(materials) == 0 && !prices_count">
                    <v-col class="pb-0" v-if="show('packages', 'total_weight')">
                        <v-alert dense type="warning" icon="mdi-alert" class="mb-0">Total package weight is 0 kgs.</v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="order.weight_forced">
                    <v-col class="pt-0 pb-1" v-if="show('packages', 'total_weight')">
                        <v-alert dense type="warning" icon="mdi-alert" class="mb-0 caption">No weight. Default ({{ package_defaults.weight | formatNumber(3) }} kg) used</v-alert>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col v-if="show('packages', 'cubic_weight') && cubic_weight" class="caption py-0 pr-5">Cubic Weight (kg): {{ Number(cubic_weight) | formatNumber(3) }}</v-col>            
                    <v-col v-if="show('packages', 'total_weight')" class="caption text-right py-0 pr-5">Total Weight (kg): {{ Number(weight) + Number(materials) | formatNumber(3) }}</v-col>
                </v-row>
            </template>
            <template v-else>
                <div v-for="(p, index) in order.packages" :key="index">
                    <v-row v-if="editing != index" class="small">
                        <v-col class="flex-grow-1 clickable" @click="editing  = index">
                            <span class="font-weight-bold">Package {{ index + 1 }} : </span>{{ p.name }} (<span v-if="p.dimensions[0]">{{ p.dimensions[0] }}</span> <span v-if="p.dimensions[0]">x{{ p.dimensions[1] }}</span> <span v-if="p.dimensions[0]"><span v-if="p.dimensions[2]">x</span>{{ p.dimensions[2] }}</span>)
                            {{ Number(p.weight) + Number(p.materials) | formatNumber(3) }}kg
                            <v-spacer></v-spacer>
                            
                        </v-col>
                        <v-col cols="auto">
                            <v-btn icon x-small right color="indigo" class="mr-1" @click="editing = index">
                                <v-icon dark>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon x-small right color="red" @click="delete_package(index)">
                                <v-icon dark>mdi-delete-forever</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <template v-if="editing == index">
                        <v-divider v-if="index > 0"></v-divider>
                        <v-row v-if="editing == index">
                            <v-col class="pb-1 pt-1 flex-grow-1">
                                <v-select
                                    v-model="p.selected"
                                    :items="packages_list.items"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    outlined 
                                    dense
                                    hide-details
                                    :label="'Package ' + Number(index + 1)"
                                    placeholder="Select a package"
                                    class="mb-0 caption"
                                    @change="select_multi(index, p.selected)"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="pr-1">{{ item.name }}</span>
                                        <span v-if="item.id !== 'custom' && (item.dimensions[0] || item.dimensions[1] || item.dimensions[2])">
                                            (<span v-if="item.dimensions[0]">{{ item.dimensions[0] }}</span> <span v-if="item.dimensions[0]">x {{ item.dimensions[1] }}</span> <span v-if="item.dimensions[0]"><span v-if="item.dimensions[2]">x</span> {{ item.dimensions[2] }}</span>)
                                        </span> 
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon x-small right color="red" @click="delete_package(index)">
                                    <v-icon dark>mdi-delete-forever</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="py-1">
                                    <v-text-field outlined dense reverse hide-details label="Length (cm)" v-model="p.dimensions[0]" class="py-0">
                                        <template v-slot:label>
                                            <span class="font-13">Length (cm)</span>
                                        </template>
                                    </v-text-field>
                            </v-col>
                            <v-col cols="4" class="py-1">
                                    <v-text-field outlined dense reverse hide-details label="Width (cm)" v-model="p.dimensions[1]">
                                        <template v-slot:label>
                                            <span class="font-13">Width (cm)</span>
                                        </template>
                                    </v-text-field>
                            </v-col>
                            <v-col cols="4" class="py-1">
                                    <v-text-field outlined dense reverse hide-details label="Height (cm)" v-model="p.dimensions[2]">
                                        <template v-slot:label>
                                            <span class="font-13">Height (cm)</span>
                                        </template>
                                    </v-text-field>
                            </v-col>
                            <v-col v-if="selected == 'custom' && (!(p.dimensions[0] > 0) || !(p.dimensions[1] > 0) || !(p.dimensions[2] > 0))" cols="12" class="pb-0 mb-2">
                                <v-alert dense type="warning" icon="mdi-alert" class="mb-0">A value must be entered for each dimension</v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="show('packages', 'item_weight')" cols="5" class="py-1">
                                    <v-text-field outlined dense reverse hide-details label="Total (kg)" v-model.number="p.weight"></v-text-field>
                            </v-col>
                            <v-col v-if="show('packages', 'item_weight') && show('packages', 'packaging_weight')" class="text-center pt-2">+</v-col>
                            <v-col v-if="show('packages', 'packaging_weight')" cols="5" class="py-1">
                                    <v-text-field outlined dense reverse hide-details label="Materials (kg)" v-model.number="p.materials"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="Number(p.weight) + Number(p.materials) == 0 && !prices_count">
                            <v-col class="pb-0" v-if="show('packages', 'total_weight')">
                                <v-alert dense type="warning" icon="mdi-alert" class="mb-0">Total package weight is 0 kgs.</v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if="order.weight_forced">
                            <v-col class="pt-0 pb-1" v-if="show('packages', 'total_weight')">
                                <v-alert dense type="warning" icon="mdi-alert" class="mb-0 caption">No weight. Default ({{ package_defaults.weight | formatNumber(3) }} kg) used</v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col v-if="show('packages', 'cubic_weight') && cubic_weight" class="caption py-0 pr-5">Cubic Weight (kg): {{ Number(cubic_weight) | formatNumber(3) }}</v-col>            
                            <v-col v-if="show('packages', 'total_weight')" class="caption text-right py-0 pr-5">Total Weight (kg): {{ Number(p.weight) + Number(p.materials) | formatNumber(3) }}</v-col>
                        </v-row>
                        <v-divider v-if="index > 0"></v-divider>
                    </template>
                </div>
              
            </template>
            <v-row>
                <v-col class="text-right">
                    <v-btn @click="add_package" text small color="primary" class="text-none"><v-icon>mdi-plus-box-outline</v-icon> Add a package</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";

export default {
    props: ['order'],
    data: function () {
        return {
            selected: null,
            dimensions: [null, null, null],
            weight: null,
            materials: 0,
            dialog: false,
            additional_packages: false,
            editing: 0,
            observer: null
        }
    },
    watch: {
        order_package: function(newVal, oldVal) {
//            this.set_package()
            this.set_weight()
        }    
    },
    methods: {
        initializeObserver() {
            const options = {
                root: null,
                threshold: 0.1, // Adjust as needed
            };

            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.set_package();
                    }
                });
            }, options);
            this.observer.observe(this.$refs.childComponent);
        },
        add_package: function () {
            if(!this.order.packages || !this.order.packages.length) {
                Vue.set(this.order, 'packages', [])
                this.order.packages.push( JSON.parse(JSON.stringify(this.order_packages[0])) )    
            }
            this.order.packages.push( JSON.parse(JSON.stringify(this.order_packages[this.order_packages.length - 1])) )
            this.editing = this.order.packages.length - 1
            this.save()
        },
        select_single: function (id) {
            if(id === 'custom') {
                Vue.set(this.order, 'dimensions', [...this.packages.custom_dimensions])
                Vue.set(this.order, 'materials', this.packages.materials)
            } else if (id) {
                Vue.set(this.order, 'dimensions', this.packages_list.details[id].dimensions)
                Vue.set(this.order, 'materials', this.packages_list.details[id].materials)
            }
            Vue.set(this.order, 'package', id)
            console.log('select_single')
            this.set_package()
            Vue.set(this.order, 'save_event', 'SelectPackage')
            this.save()
        },
        select_multi: function (index, item) {
            const pkg = this.order.packages[index]
            pkg.name = this.packages_list.details[item].name
            pkg.dimensions = this.packages_list.details[item].dimensions
            pkg.materials = this.packages_list.details[item].materials
            this.save()
        },
        delete_package: function (index) {
            this.order.packages.splice(index, 1)
            if(this.editing > (index - 1)) {
                this.editing = this.editing > 0 ? this.editing - 1 : 0
            }
            this.save()
        },
        save_dimensions: function () {
            this.order.package = 'custom'
            Vue.set(this.order, 'dimensions', this.dimensions)
            //this.set_package()
            Vue.set(this.order, 'save_event', 'SaveDimensions')
            this.save()
        },
        save_weight: function () {
            Vue.set(this.order, 'weight', this.weight * 1000)
            Vue.set(this.order, 'materials', this.materials)
            Vue.set(this.order, 'save_event', 'SaveWeight')
            this.save()
        },
        save: function () {
            this.$store.dispatch('Orders/save', this.order)
            if(this.layout != 'table') {
                this.$store.dispatch('Quotes/reset_quote', this.order)
            }
        },
        set_package: function () {
            const p = orderTools.get_package(this.order)[0]
            this.selected = p.selected
            this.dimensions = [...p.dimensions]
            this.materials = p.materials || (this.materials ? this.materials : 0)
        },
        set_weight: function () {
            this.weight = mergeTools.get_order_weight(this.order) / 1000
        },
        show(section, subsection) {
			try {
				if(subsection) {
					return this.display_settings[this.layout][section].subsections[subsection].status
				} else {
					return this.display_settings[this.layout][section].status
				}
			} catch (e) {
				return true
			}
        },
    },
    mounted: function () {
        this.initializeObserver();
//        this.set_package()
        this.set_weight()
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect(); // Disconnect the observer
        }
    },
    updated: function () {
//        this.save()
    },
	computed : {
		...mapGetters({
            packages: 'Packages/packages',
            packages_list: 'Packages/list',
            package_defaults: 'Packages/defaults',
            display_settings: 'Settings/display_settings',
        }),
        layout: function () {
            return this.$store.getters[`Settings/order_layout`](this.order)
        },

        order_package() {
            return orderTools.get_package(this.order)[0]
        },
        order_packages() {
            return orderTools.get_package(this.order)
        },
        prices_count() {
            let count = 0
            const carrier_quotes = orderTools.get_all_prices(this.order)
            carrier_quotes.forEach(carrier => {
                count = count + carrier.length
            })
            return count
        },
        cubic_weight() {
            try {
                return (this.dimensions[0] * this.dimensions[1] * this.dimensions[2]) / 1000000 * 250
            } catch (e) {
                return null
            }
        }
	}
}
</script>

<style>
    div.packages .v-text-field__slot input {
        height: 24px;
        padding-top:2px;
        padding-bottom: 2px;
    }
</style>

