// Carriers - eParcel
import Vue from 'vue'
import axios from 'axios'
import orderTools from "@/V2/helpers/orderTools";
import eparcelTools from "@/V2/integrations/eParcel/helpers/eparcelTools";
import { getSessionToken } from '@shopify/app-bridge-utils';

// Carriers - eParcel
export default {
	namespaced: true,
	state: {
		beta: false,
		config: {
			name: 'AusPost - eParcel/ParcelSend',
			logo: '/img/auspost2.png',
		},
		products: {},
		grouped_products: [],
		settings: {
			hidden: [],
			domestic: {
				email_tracking: true,
				signature_options: 'authority_to_leave',
				contains_dangerous_goods: false,
				transportable_by_air: false,
				dangerous_goods_declaration: '',
				allow_partial_delivery: false,
				transit_cover: false,
				transit_cover_percentage: 100,
				item_description: null,
				modified: null
			},
			international: {
				international_parcel_sender_name: null,
				email_tracking: true,
				transit_cover: false,
				transit_cover_percentage: 100,
				commercial_value: true,
				contains_dangerous_goods: false,
				transportable_by_air: false,
				dangerous_goods_declaration: '',
				export_declaration_number: '',
				import_reference_number: '',
				classification_type: 'SALE_OF_GOODS',
				description_of_other: null,
				non_delivery_action: 'RETURN',
				certificate_number: null,
				licence_number: null,
				comments: '',
				description: null,
				country_of_origin: 'AU',
				tariff_code: null,
				hide_weights: false,
				modified: null
			},
			printer: {
				parcel_post: {
					label: 'THERMAL-LABEL-A6-1PP',
					branded: true,
					left_offset: 0,
					top_offset: 0
				},
				express_post: {
					label: 'THERMAL-LABEL-A6-1PP',
					branded: true,
					left_offset: 0,
					top_offset: 0
				},
				international: {
					label: 'THERMAL-LABEL-A6-1PP',
					left_offset: 0,
					top_offset: 0
				} 
			},
		},

	},
	mutations: {
		init_products: function (state, products) {
			state.products = {...state.products, ...products}
		}
	},
	getters: {
		config: state => {
			return state.config
		},
		beta: state => state.beta,
		get_prices: (state, getters, rootState, rootGetters) => (payload) => {
			const arr: Array<any> = []
			const hidden = eparcelTools.get_hidden_services(payload.carrier)
			if(payload.carrier && payload.quotes) {
				payload.quotes.shipments.forEach( ( shipment ) => {
					if(!hidden[shipment.items[0].product_id]){
						arr.push({
							carrier_id: payload.carrier.carrier_id,
							carrier_type: payload.carrier.carrier,
							carrier_name: payload.carrier.name,
							code: shipment.items[0].product_id,
							description: payload.carrier.products[shipment.items[0].product_id].description,
							price: shipment.shipment_summary.total_cost,
							additional_data: {
								"Shipping Cost": '$' + shipment.shipment_summary.shipping_cost.toFixed(2),
								"Fuel Surcharge": '$' + (shipment.shipment_summary.fuel_surcharge ? shipment.shipment_summary.fuel_surcharge : 0).toFixed(2),
								"Security Surcharge": '$' + (shipment.shipment_summary.security_surcharge ? shipment.shipment_summary.security_surcharge : 0).toFixed(2),
								"Cost (ex GST)": '$' + shipment.shipment_summary.total_cost_ex_gst.toFixed(2),
								"GST": '$' + shipment.shipment_summary.total_gst.toFixed(2),
								"Total Cost": '$' + shipment.shipment_summary.total_cost.toFixed(2)
							}
						})
					}
				})
			}
			arr.sort(function(a, b) {
				return parseFloat(a.price) - parseFloat(b.price);
			});
			return arr
		},
		has_tracking: () => {
			return true
		},
		logo: state => {
			return state.config.logo
		},
		next_step: () => (order) => {
			if(order.consignment && order.consignment.shipment && order.consignment.shipment.items && order.consignment.shipment.order_id) {
				return 'fulfill_order'
			}
			if(order.consignment && order.consignment.shipment && order.consignment.shipment.items && !order.consignment.shipment.order_id) {
				return 'manifest_label'
			}
			return 'print_label'
		},
		product: (state) => (id) => {
			return state.products[id]
		},
		service_level: (state) => (service) => {
			const product = state.products[service.code]
			if(product) {
				return product.service_level
			}
			return null
		},
		settings: (state) => {
			return state.settings
		},
/*		actions: () => (order) => {
			try {
				return {
					carrier: 'eParcel',
					print: !order.consignment || order.consignment && ! order.consignment.shipment ? ['Create Label', 'Label', 'create'] : null,
					update: order.consignment && order.consignment.shipment && !order.consignment.shipment.order_id ? ['Update Label', 'Update', 'create'] : null, 
					manifest: order.consignment && order.consignment.shipment && !order.consignment.shipment.order_id ? ['Create Manifest', 'Manifest', 'pay'] : (order.consignment && order.consignment.shipment && order.consignment.shipment.order_id ? ['Reprint Manifest', 'Manifest', 'pay'] : null),
					reprint: order.consignment && order.consignment.shipment ? ['Reprint Label', 'Label', 'reprint'] : null
				} 
	
			} catch (e) {
				console.log(e)
			}
		}, */
		actions: () => (order) => {
			try {
				return {
					create: ['Print Label', 'Label', 'print'],
					manifest: order.consignment && order.consignment.shipment && !order.consignment.shipment.order_id ? ['Create Manifest', 'Manifest', 'manifest'] : (order.consignment && order.consignment.shipment && order.consignment.shipment.order_id ? ['Reprint Manifest', 'Manifest', 'manifest'] : null),
				} 
	
			} catch (e) {
				console.log(e)
			}
		},
		bulk_actions: () => (payload) => {
			const actions: Array<string> = []
			if(payload.statuses['no_consignment'].length) {
				actions.push('print')
			}
			if(payload.statuses['unpaid_consignment'].length) {
				actions.push('print')
				actions.push('manifest')
			}
			if(payload.statuses['paid_consignment'].length) {
				actions.push('print')
				actions.push('manifest')
			}
			return [... new Set(actions)]
		},
		consignment_status: () => (order) => {
			if(!order.consignment || (order.consignment && !order.consignment.shipment)){
				return 'no_consignment'
			}
			if(order.consignment && order.consignment.shipment && !order.consignment.shipment.order_id){
				return 'unpaid_consignment'
			}
			if(order.consignment && order.consignment.shipment && order.consignment.shipment.order_id){
				return 'paid_consignment'
			}
		},
		redact_customer: () => (consignment) => {
			if(consignment.data && consignment.data.to) {
				delete consignment.data.to
			}
			if(consignment.shipment && consignment.shipment.to) {
				delete consignment.shipment.to
			}
			return consignment
		},
		tracking_company: () => {
			return 'Australia Post'
		},
		tracking_number: () => (consignment) => {
			try {
				return consignment.shipment.items[0].tracking_details.article_id
			} catch (e) {
				return null
			}
		},
		tracking_url: () => (consignment) => {
			try {
				return `https://auspost.com.au/track/${consignment.shipment.items[0].tracking_details.article_id}`
			} catch (e) {
				return null
			}
		},
		dangerous_goods_list: () => {
			return [
				'UN1845_DryIce_and_UN3373_BioSubstance_B',
				'UN2910_radioactive_excepted_limited_qty',
				'UN2911_radioactive_excepted_instruments_or_articles',
				'UN3091_Lithium_MetalAndAlloy_contained_in_equipment',
				'UN3373_BioSubstance_B',
				'UN3481_Lithium_IonOrPolymer_contained_in_equipment',
				'UN3091_Lithium_MetalAndAlloy_contained_in_equipment',
				'UN3481_Lithium_IonOrPolymer_contained_in_equipment'			
			]
		},
		warnings: () => (order) => {
			const arr: Array<any> = []
			// No international description
			if(!orderTools.is_domestic(order)) {
				const options = order.orderOptions[order.rate_selected.carrier_id].international
				if(!options.description && (options.market_value  || orderTools.count_fulfillable_line_items(order) > 20)) {
					arr.push('A Goods Description should be entered for this order to ensure Customs clearance. Expand the order to edit the Order Options')
				}
			}
			return arr
		}
	},
	actions: {
		init: async function ({ state, dispatch, commit }, payload) {
			const account = await dispatch('validate', payload.credentials)
			const products = await dispatch('get_products', account)
			commit('init_products', products)
			return { data: account, products: products, packages: state.packages }
		},
		action_create: async function({ dispatch }, payload) {
			dispatch('action_print', payload)
		},
		action_update: async function({ dispatch }, payload) {
			dispatch('action_print', payload)
		},
		action_print: async function({ commit }, payload) {
			const success = await eparcelTools.create_consignments(payload.orders)
			if(success) {
				const label = await eparcelTools.create_labels(payload.orders)
				return true
			}
		},
		action_manifest: async function({ dispatch }, payload) {
			// only process orders that have already had a consignment created
			const arr: Array<any> = []
			payload.orders.forEach(o => {
				if(o.consignment && o.consignment.shipment) {
					arr.push(o)
				}
			})
			await eparcelTools.create_manifest(arr)
		},
		action_pay: async function({ dispatch }, payload) {
			dispatch('action_manifest', payload)
		},
/*		action_create: async function({ commit }, payload) {
			const success = await eparcelTools.create_consignments(payload.orders)
			if(success) {
				const label = await eparcelTools.create_labels(payload.orders)
				return true
			}
		},
		action_pay: async function({ dispatch }, payload) {
			await eparcelTools.create_manifest(payload.orders)
		},
		action_pay_print: async function({ dispatch }, payload) {
			await eparcelTools.create_labels(payload.orders)
		},
		action_reprint: async function({ dispatch }, payload) {
			await eparcelTools.create_labels(payload.orders)
		}, */
		delete_labels: function (context, payload) {
			eparcelTools.delete_shipments(payload)
		},
		get_products: function ({ commit }, payload) {
			const products = {}
			if(payload.postage_products.length) {
				payload.postage_products.forEach( ( product ) => {
					products[product.product_id] = {
						id: product.product_id,
						description: product.type,
						official_name: product.type,
						domestic: product.type.match(/INT/g) ? false : true,
						service_level: product.type.match(/EXPRESS/) ? 'express' : (product.type.match(/ECONOMY/) ? 'economy' : 'standard'), 
						data: product
					}
				})
			}
			commit('init_products', products)
			return products
		},
		get_quote: async function ({ dispatch, rootGetters }, payload) {
			await dispatch('Orders/reset_errors', { orders: [payload.order], type: 'quote'}, { root: true })
			const shipments = eparcelTools.get_rate_request_body( {order: payload.order, carrier_id: payload.carrier_id} )
			if(shipments) {
				const address_error = await dispatch('validate_address', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id) })
				const result = address_error ? null : await dispatch('get_shipment_price', { order: payload.order, carrier: rootGetters['Carriers/carrier'](payload.carrier_id), shipments: shipments })
				if(result) {
					return ({
						request: JSON.stringify(shipments),
						data: address_error ? null : result.quote, 
						errors: result.errors,
						address_error: address_error,
						statusCode: result.statusCode
					})
				} else {
					return {
						request: JSON.stringify(shipments),
						data: null,
						errors: null,
						address_error: address_error,
						statusCode: null
					}
				}
			} 
			return {
				request: null,
				data: null,
				errors: null,
				address_error: 'xxx',
				statusCode: null
			}
		},
		get_prices_array: function ( { rootGetters }, payload) {
			const carrier = rootGetters['Carriers/carrier'](payload.carrier)
			const arr: Array<any> = []
			if(carrier){

				payload.quotes.shipments.forEach( ( shipment ) => {
					arr.push({
						carrier_id: carrier.carrier_id,
						carrier_type: carrier.carrier,
						carrier_name: carrier.name,
						code: shipment.items[0].product_id,
						description: carrier.products[shipment.items[0].product_id].description,
						price: shipment.shipment_summary.total_cost
					})
				})
	
				arr.sort(function(a, b) {
					return parseFloat(a.price) - parseFloat(b.price);
				});
	
			}
			return arr
		},
		update: function ({ state }, carrier) {
			carrier.settings.domestic = {...state.settings.domestic, ...carrier.settings.domestic}
			carrier.settings.international = {...state.settings.international, ...carrier.settings.international}
			carrier.settings.printer = {...state.settings.printer, ...carrier.settings.printer}
			return carrier
		},
		get_rate_request_body: function (context, payload) {
			return eparcelTools.get_rate_request_body(payload)
		},
		get_shipment_price: async function ({ dispatch, rootGetters }, payload ) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/post`, {request: payload.shipments},
			{
				headers: { 
					'Authorization': token,
					'account_number': payload.carrier.credentials.account_number,
					'api_key': payload.carrier.credentials.api_key,
					'secret': payload.carrier.credentials.secret,
					'resource': `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}/shipping/v1/prices/shipments`
				}
			})
			.then(response => {
				if(response.data.body){
					if(response.data.body.errors) {
						dispatch('Orders/init_error', { order: payload.order, type: 'quote', error: `eParcel Error: ${eparcelTools.nicer_message(response.data.body.errors[0])}` }, { root: true })
					}
					return { 
						quote: response.data.body.shipments ? response.data.body : null,
						errors: response.data.body.errors ? response.data.body : null
					}
				} else {
					return {
						quote: null,
						errors: 'An error occurred requesting a rate quote from the AusPost eParcel system.'
					}
				}
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'eparcel/get_shipment_price', data: err})
			})
		},
        validate: async function({ rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/validate`, { 
				account_number: payload.account_number,
				api_key: payload.api_key,
				secret: payload.secret,
				path: `${process.env.VUE_APP_EPARCEL_DEV ? '/test' : ''}`
			},
			{
				headers: { 
					'Authorization': token,
				}
			})
			.then(response => {
				return (response.data.body)
			})
			.catch(err => {
				orderTools.log_error({message: 'Network Error', stack: 'eparcel/validate', data: err})
			})
		},
		validate_address: async function ({ dispatch, rootGetters }, payload) {
			const token = await getSessionToken(rootGetters['Shopify/app']);
			if(payload.order.shipping_address) {
				// Don't need to validate international addresses
				if(payload.order.shipping_address.country_code !== 'AU'){
					return null
				}
				// Do our own address validation first
				return dispatch('Quotes/validate_address', payload.order, { root: true }).then(address_error => {
					if(address_error) {
						// If we can't validate it try AusPost's validation
						const query = [
							`suburb=${payload.order.shipping_address.city}`,
							`state=${payload.order.shipping_address.province_code}`,
							`postcode=${payload.order.shipping_address.zip}`
						].join('&')
			
						return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/eparcel/get`, {
							query: encodeURI(query)
						},
						{
							headers: { 
								'Authorization': token,
								'account_number': payload.carrier.credentials.account_number,
								'api_key': payload.carrier.credentials.api_key,
								'secret': payload.carrier.credentials.secret,
								'resource': `${process.env.VUE_APP_EPARCEL_DEV ? 'test' : ''}/shipping/v1/address`
							}
						})
						.then(response => {
							if(response.data && response.data.body){
								if(response.data.body.found){
									dispatch('Orders/reset_errors', { orders: [payload.order], type: 'address'}, { root: true })
									return null
								}
								if(response.data.body.results && response.data.body.results.length) {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. Possible matches to the postcode entered include ' + response.data.body.results.join(', ') }, { root: true })
								} else {
									dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'The suburb / postcode do not match. No suggested matches found.' }, { root: true })
								}
								return response.data.body
							}
						})
						.catch(err => {
							orderTools.log_error({message: 'Network Error', stack: 'eparcel/validate_address', data: err})
						})
					}
					return null
				})
	
			}
		},
		validate_fulfillment: function (context, order) {
			const error_msg = 'The consignments must be manifested before you can fulfill this order.'
			try {
				if(order.consignment.shipment.order_id) {
					return true
				} else {
					return error_msg
				}
			} catch (e) {
				return error_msg
			}
		},
		set_order_options: function (context, payload) {
			eparcelTools.set_order_options(payload)
		}
	},
	modules: {
	}
}
