<template>
    <v-card class="mt-4" outlined>
        <v-toolbar color="indigo" dark dense>
                <v-tabs  show-arrows v-model="currentItem" slider-color="white">
                    <v-tab href="#tab-0">Order Tests</v-tab>
                    <v-tab href="#tab-1">Manual Tests</v-tab>
                </v-tabs>
        </v-toolbar>        
        <v-tabs-items v-model="currentItem">
            <v-tab-item value="tab-0" class="pa-2">
                <OrderTests/>
            </v-tab-item>
            <v-tab-item value="tab-1" class="pa-2">
                <ManualTests/>
            </v-tab-item>
        </v-tabs-items> 
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import OrderTests from '@/V2/components/LiveRates/OrderTests.vue'
import ManualTests from '@/V2/components/LiveRates/ManualTests.vue'

export default {
    data: function () {
        return {
            currentItem: 0,
        }
    },
    components: {
        OrderTests,
        ManualTests
    },
}
</script>

