<template>
	<div>
		<div v-if="!loading || requoting" class="mt-16 mt-md-0 py-8">
			<v-app-bar fixed color="white" class="mt-12 pt-1" extension-height="40" :height="header_size" style="z-index: 200">
				<v-row class="pt-1 debug-border">
					<v-col cols="12" sm="12" md="12" lg="8" xl="9" class="debug-border pb-0 pb-md-4 pt-md-0 pt-lg-4">
						<v-row no-gutters>
							<v-col cols="2" sm="1" md="2" lg="1" xl="1" class="debug-border">
								<v-checkbox hide-details v-model="select_all" class="mt-0 pl-4"></v-checkbox>
							</v-col>
							<v-col cols="7" sm="5" md="5" lg="6" xl="3" class="debug-border">
								<v-text-field @input="debounceInput" dense clearable flat outlined hide-details prepend-inner-icon="mdi-magnify" label="Filter" class="mr-1"></v-text-field>
							</v-col>
							<v-col cols="2" sm="4" md="3" lg="3" xl="2" v-if="carriers_filter.length" class="hidden-xs-only debug-border">
								<v-select 
									v-model="filtered_carrier"
									clearable
									dense 
									outlined
									hide-details
									label="Filter by carrier"
									:items="carriers_filter"
									class="mr-1"
								></v-select>
							</v-col> 
							<v-col cols="3" sm="2" md="2" lg="2" xl="2" class="pl-md-2 text-sm-left text-md-right text-lg-left debug-border">
								<v-btn-toggle v-model="sortDesc" mandatory>
									<v-tooltip bottom color="indigo">
										<template v-slot:activator="{ on, attrs }">
											<v-btn x-small outlined :value="false" v-bind="attrs" v-on="on" class="py-5">
												<v-icon>mdi-arrow-up</v-icon>
											</v-btn>
										</template>
										<span>Sort Ascending</span>
									</v-tooltip>
									<v-tooltip bottom color="indigo">
										<template v-slot:activator="{ on, attrs }">
											<v-btn small outlined :value="true" v-bind="attrs" v-on="on" class="py-5">
												<v-icon>mdi-arrow-down</v-icon>
											</v-btn>
										</template>
										<span>Sort Descending</span>
									</v-tooltip>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="12" md="12" lg="4" xl="3" order-sm="first" order-lg="last" class="pt-1 debug-border">
						<v-row no-gutters>
							<v-col sm="8" md="9" lg="7" xl="7" class="text-sm-center text-md-right debug-border pt-4">
								<BulkActions :selected="selected" :has_deferrals="has_deferrals" v-on:action="action($event, sorted_selected_orders)"/>
							</v-col>
							<v-col sm="4" md="3" lg="5" xl="5" class="text-right debug-border pt-4">
								<!-- Pagination -->
								<div v-if="numberOfPages">
									<span class="mr-4 caption grey--text">
										Page {{ page }} of {{ numberOfPages }}
									</span>
									<v-btn fab x-small dark color="blue darken-3" class="mr-1" @click="formerPage">
										<v-icon>mdi-chevron-left</v-icon>
									</v-btn>
									<v-btn fab x-small dark color="blue darken-3" class="ml-1" @click="nextPage">
										<v-icon>mdi-chevron-right</v-icon>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-col>

				</v-row>

				<!-- Tabs -->
				<template v-slot:extension>
					<v-tabs show-arrows v-model="displayed_tab">
						<v-tab @change="set_tab('all'); scroll()">All Orders ({{item_count('all')}})</v-tab>
						<v-tab v-if="item_count('selected')" @change="set_tab('selected'); scroll()">Selected Orders ({{item_count('selected')}})</v-tab>
						<v-tab v-if="item_count('no_label')" @change="set_tab('no_label'); scroll()">No Label ({{item_count('no_label')}})</v-tab>
						<v-tab v-if="item_count('label_initiated')" @change="set_tab('label_initiated'); scroll()">Label Initiated ({{item_count('label_initiated')}})</v-tab>
						<v-tab v-if="item_count('label_finalised')" @change="set_tab('label_finalised'); scroll()">Label Finalised ({{item_count('label_finalised')}})</v-tab>
						<v-menu open-on-click bottom right offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-tab v-bind="attrs" v-on="on">
									{{ tab_name || 'More Filters' }}
									<v-icon dark right>mdi-chevron-down</v-icon>
								</v-tab>
							</template>
							<v-list class="pointer">        
								<v-list-item v-for="carrier in carriers_with_orders" :key="carrier.carrier_id">
									<v-list-item-title @click="set_tab(carrier.carrier_id); scroll()">Assigned to {{carrier.name ? carrier.name : carrier.carrier}} ({{item_count(carrier.carrier_id)}})</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item_count('not_assigned')">
									<v-list-item-title @click="set_tab('not_assigned'); scroll()">No Carrier Assigned ({{item_count('not_assigned')}})</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item_count('pickup')">
									<v-list-item-title @click="set_tab('pickup'); scroll()">Pickup Orders ({{item_count('pickup')}})</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item_count('no_shipping')">
									<v-list-item-title @click="set_tab('no_shipping'); scroll()">No Shipping Required ({{item_count('no_shipping')}})</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu> 
						<v-tab v-if="item_count('deferred')" @change="set_tab('deferred'); scroll()">Deferred Orders ({{item_count('deferred')}})</v-tab>
						<v-tab class="red--text" v-if="label_errors.length" @change="set_tab('label_errors'); scroll()">Label Errors ({{label_errors.length}})</v-tab>
						<v-tab class="red--text" v-if="address_errors.length" @change="set_tab('address_errors'); scroll()">Address Errors ({{address_errors.length}})</v-tab>
<!--						<v-tab v-if="Object.keys(mergeable_orders).length || merge_errors.length" @change="set_tab('merge'); scroll()">
							Merge Orders 
							<v-chip v-if="unmerged.length || partial.length" small color="info" class="ml-2"><strong>{{ unmerged.length + partial.length }}</strong></v-chip>
							<v-chip v-if="merge_errors.length" small color="error" class="ml-2"><strong>{{ merge_errors.length }}</strong></v-chip>
						</v-tab> -->
					</v-tabs>
				</template>
			</v-app-bar>
 
			<MergeOrders v-if="tab == 'merge'" class="pt-13"/>
			<div v-else class="pt-16 pb-16">
				<v-data-iterator
					:items="filteredItems"
					:items-per-page.sync="itemsPerPage[view_layout]"
					:page="page"
					sort-by="order_number"
					:sort-desc="sortDesc"
					hide-default-footer
					@current-items="paginate"
					@page-count="checkMaxPage"
				>
					<template v-slot:default="props">
						<div v-for="order in props.items" :key="order.id">
							<v-row>
								<v-col cols="12" class="pb-1 py-0 px-1 px-lg-3">
									<v-card elevation="1" tile class="px-0">
										<v-row class="px-3">
											<v-col class="py-0">
												<v-alert v-if="order.errors && order.errors.label && (order.errors.label != order.errors.address)" text outlined dense dismissible icon="mdi-alert" type="error" class="mt-2" @toggle="reset_error(order, 'label')" v-html="order.errors.label">
													<v-icon slot="close" color="red" @click="reset_error(order, 'label')">mdi-close-circle</v-icon> 
												</v-alert>							
												<v-alert v-if="order.errors && order.errors.fulfillment" text outlined dense dismissible icon="mdi-alert" type="error" class="mt-2" @toggle="reset_error(order, 'fulfillment')">
													<v-icon slot="close" color="red" @click="reset_error(order, 'label')">mdi-close-circle</v-icon> 
													{{ order.errors.fulfillment }}
												</v-alert>	
												<Warnings v-if="layout(order) != 'table'" :order="order"/>
											</v-col>
										</v-row>
										
										<v-row v-if="layout(order) === 'table'" class="pt-1 pb-0">
											<v-col cols="12" class="pa-0">
												<v-checkbox hide-details v-model="selected" class="mt-0 pl-5 float-left" :value="order.id"></v-checkbox>
												<keep-alive>
													<TableView
														:order="order" 
														v-on:close="close(order)" 
														v-on:expand="expand(order)" 
														v-on:action="action($event, [order])"/>
												</keep-alive>
											</v-col>
										</v-row>
										
										<v-row v-else class="pt-2 pb-0">
											<v-col cols="5" class="pa-0">
												<v-row>
													<v-col cols="3" sm="2" md="3" lg="2" xl="2" class="pb-0" style="z-index: 100">
														<v-checkbox hide-details v-model="selected" class="mt-0 pl-5" :value="order.id"></v-checkbox>
													</v-col>
													<v-col cols="9" sm="10" md="9" lg="10" xl="10" class="py-0">
														<v-card-title class="subheading py-0" @click="expand(order)"><span v-for="(order_number, index) in merged.order_names(order)" :key="index"> {{ order_number }}<span v-if="index < merged.order_names(order).length - 1" class="mr-2">,</span> </span>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-chip v-if="order.financial_status == 'pending'" x-small class="ma-2 py-2" color="error" v-bind="attrs" v-on="on">Pending</v-chip>    
																</template>
																<span>CAUTION: Payment is pending on this order</span>
															</v-tooltip>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-chip v-if="orderTools.get_fulfillment_status(order)[0]" x-small outlined class="ma-2 py-2" color="primary" v-bind="attrs" v-on="on">{{ orderTools.get_fulfillment_status(order)[1] }}</v-chip>
																</template>
																<span>Order has been partially fulfilled</span>
															</v-tooltip>
														</v-card-title>
														<v-row no-gutters class="pl-5" @click="expand(order)">
															<v-col cols="12">
																<span class="caption py-0" v-if="show('order_summary', 'order_date')">{{ order.created_at | formatDate('DD/MM/YYYY') }} </span>
																<span v-if="layout(order) == 'table'">
																	<span class="caption py-0 hidden-md-and-down" v-if="show('order_summary', 'order_time')">{{ order.created_at | formatDate('hh:mm a') }}</span>
																</span>
																<span v-else>
																	<span class="caption py-0 hidden-xs-only" v-if="show('order_summary', 'order_time')">{{ order.created_at | formatDate('hh:mm a') }}</span>
																</span>
															</v-col>
															<v-col cols="12" class="hidden-sm-and-up">
																<span class="caption py-0" v-if="show('order_summary', 'order_time')">{{ order.created_at | formatDate('hh:mm a') }}</span>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</v-col>
											<v-col cols="7" class="py-0 pr-2 text-right">
												<Actions :order="order" class="pr-2" v-on:action="action($event, [order])" v-on:close="close(order)" v-on:expand="expand(order)"/>
											</v-col>
										</v-row>

										<!-- Body -->
										<div v-if="layout(order) === 'compact'">
											<v-divider class="ma-0"></v-divider>
											<keep-alive> 
												<Compact  
													:order="order" 
													v-on:close="close(order)" 
													v-on:expand="expand(order)" 
													v-on:action="action($event, [order])"/>
											</keep-alive> 
										</div>
										<div v-if="layout(order) === 'expanded'">
											<keep-alive> 
												<Expanded  
													:order="order" 
													v-on:close="close(order)" 
													v-on:expand="expand(order)" 
													v-on:action="action($event, [order])"/>
											</keep-alive> 
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</template>
					
					<template v-slot:footer v-if="numberOfPages">
						<v-row class="mt-2 px-6" align="center" justify="center">
							<v-spacer></v-spacer>
							<span class="mr-4 caption grey--text">
								Page {{ page }} of {{ numberOfPages }}
							</span>

							<v-btn fab x-small dark color="blue darken-3" class="mr-1" @click="formerPage">
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>
							<v-btn fab x-small dark color="blue darken-3" class="ml-1" @click="nextPage">
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</v-row>
					</template>
					<template v-slot:header>
						<v-row no-gutters>
							<v-col class="py-0">
								<v-alert v-if="system_error.length" text outlined dense icon="mdi-alert" type="error" class="my-2" v-html="system_error[0].message"></v-alert>
								<v-alert v-if="!Object.keys(carriers).length" text outlined dense dismissible icon="mdi-alert" type="info" class="my-2">
									<v-icon slot="close" color="info" @click="reset_error(order, 'label')">mdi-close-circle</v-icon> 
									You don't have a carrier connected. Click <router-link to="/carrier/summary">here</router-link> or on the CARRIERS option on the menu to add a carrier to your account.
								</v-alert>
								<v-alert type="warning" class="my-2" dense v-if="isSafari && !hide_safari_message">
									<span class="font-weight-bold">Attention Safari Users: </span>
									Some features of this app are not available in the Safari browser. We highly recommend downloading Google Chrome or Mozilla Firefox to enable all features.
								</v-alert>

							</v-col>
						</v-row>

					</template>
					
					<template v-slot:no-data>
						<Startup v-if="0" title="Test" message="Sample message"/>
						<v-row>
							<v-col>
								<v-card class="mx-auto mt-8" max-width="444" min-height="150">
									<v-toolbar dark color="primary" dense flat>
										<v-toolbar-title v-if="!orders.length" class="white--text">No Orders Found</v-toolbar-title>
										<v-toolbar-title v-else class="white--text">No Matching Orders Found</v-toolbar-title>
									</v-toolbar>
									<div v-if="orders.length">
										<v-card-text v-if="search">The search term you've entered does not match any orders</v-card-text>
										<v-card-text v-else>No orders were found using the selected filter</v-card-text>
									</div>
									<div v-else>
										<v-card-text>No paid and unfulfilled orders could be found</v-card-text>
									</div>
								</v-card>
								
							</v-col>
						</v-row>
					</template>
				</v-data-iterator>
			</div> 
		</div>
		<ProgressDialog />
		<LabelErrorDialog ref="label_error" :orders="label_errors"/> 
		<FulfillmentErrorDialog ref="fulfillment_error" :orders="fulfillment_errors"/> 
		<WarningDialog ref="warning"/> 
		<FulfillDialog ref="fulfill"/> 
		<TemplateDialog ref="template"/>
		<SettingsDialog ref="settings"/>
		<PopupBlockedDialog ref="popup"/>
		<PopupSystemErrorDialog ref="system"/>
		<ConfirmDialog ref="confirm"/>
        <v-snackbar v-model="alert.status" :color="alert.color" top :timeout="alert.timeout">
            {{ alert.message }}
            <template v-slot:action="{ attrs }">
                <v-btn outlined v-bind="attrs" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import TableView from '@/V2/components/Orders/TableView/Home'
import Compact from '@/V2/components/Orders/Compact/Home'
import Expanded from '@/V2/components/Orders/Expanded/Home'
import Actions from '@/V2/components/Orders/Actions'
//import BulkActions from '@/V2/components/Orders/BulkActions'
import BulkActions from '@/V2/components/Orders/BulkActionsV2'
import ProgressDialog from '@/V2/components/Orders/ProgressDialog'
import WarningDialog from '@/V2/components/Utilities/WarningDialog'
import LabelErrorDialog from '@/V2/components/Utilities/ErrorDialog'
import FulfillmentErrorDialog from '@/V2/components/Utilities/ErrorDialog'
import FulfillDialog from '@/V2/components/Orders/FulfillDialog'
import TemplateDialog from '@/V2/components/Orders/TemplateDialog'
import SettingsDialog from '@/V2/components/Utilities/SettingsDialog'
import PopupBlockedDialog from '@/V2/components/Utilities/PopupBlockedDialog'
import PopupSystemErrorDialog from '@/V2/components/Utilities/PopupSystemErrorDialog'
import consignments from "@/V2/helpers/consignments";
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import fulfillments from "@/V2/helpers/fulfillments";
import ConfirmDialog from '@/V2/components/Utilities/ConfirmDialog.vue'
import Warnings from '@/V2/components/Orders/Warnings'
import MergeOrders from '@/V2/views/Merge'
import Response from '@/V2/components/Utilities/Response'
import Startup from '@/V2/components/Utilities/Startup'

export default {
	data: function () {
		return {
			itemsPerPageArray: [10, 25, 50, 100, 200],
			page: 1,
			selected: [],
			filtered_carrier: null,
			search: '',
			filter: {},
			sortBy: '',
			sortDesc: true,
			select_all: false,
			tab: 'all',
			displayed_tab: null,
			alert: {
				status: false,
				message: null,
				color: 'success',
				timeout: 5000
			},
			hidden_alerts: {},
			hide_safari_message: false,
			orderTools: orderTools,
			merged: mergeTools
		}
	},
	components: {
		TableView,
		Compact,
		Expanded,
		Actions,
		BulkActions,
		ProgressDialog,
		LabelErrorDialog,
		FulfillmentErrorDialog,
		WarningDialog,
		FulfillDialog,
		TemplateDialog,
		SettingsDialog,
		PopupBlockedDialog,
		PopupSystemErrorDialog,
		ConfirmDialog,
		Warnings,
		MergeOrders,
		Response,
		Startup
	},
	watch: {
		select_all: function(newVal, oldVal) { 
			const arr = []
			if(newVal == true) {
				for(const order of this.filteredItems) {
					arr.push(order.id)
				}
			}
			this.selected = arr
		},
		filteredItems (v) {
			if(this.tab != 'all' && v.length == 0){
				this.tab = 'all'
				this.displayed_tab = 0
				this.scroll()
			}
		},
		tab_name (v) {
			if (v) {
				if (document.createEvent) { // W3C
					const ev = document.createEvent('Event');
					ev.initEvent('resize', true, true);
					window.dispatchEvent(ev);
				} else { // IE
					document.fireEvent('onresize');
				} 
			}
		},
		new_orders (orders) {
			if(orders.length > 0) {
				this.alert.message = `New order${orders.length > 1 ? 's' : ''} imported: ${orders.join(', ')}`
				this.alert.status = true
			}
		},
		fulfillment_errors (errors, old_errors) {
			if(errors.length && !old_errors.length && !this.$refs.fulfillment_error.dialog) {
				this.$refs.fulfillment_error.open('Error', `Errors occurred while fulfilling orders. <br><br>Resolve the errors before attempting to fulfill the orders again.`, errors, 'fulfillment', { color: 'red darken-2', width: 700 })
			}
		},
		label_errors (errors, old_errors) {
			if(errors.length && !old_errors.length && !this.$refs.label_error.dialog) {
				this.$refs.label_error.open('Error', 'Errors occurred while printing labels. <br><br>Click the LABEL ERRORS tab to view all orders with errors.', errors, 'label', { color: 'red darken-2', width: 600 })
			}
		},
		popup_blocked () {
			if(this.popup_blocked.length && !this.$refs.popup.dialog) {
				this.$refs.popup.open()
			}
		},
		system_error_message () {
			if(this.system_error_message.length && !this.$refs.system.dialog) {
				this.$refs.system.open()
			}
		},
		sortDesc () {
			localStorage.setItem('sort', JSON.stringify(this.sortDesc))
		}
	},
	methods: {
		set_tab: function (val) {
			this.tab = val
		},
		debounceInput: _.debounce(function (e) {
			this.search = e;
		}, 500),
		hide_alert: function(alert) {
			this.hidden_alerts[alert] = true
			localStorage.setItem('hidden_alerts', JSON.stringify(this.hidden_alerts))
		},
		toggle_layout: function(order) {
			Vue.delete(order, 'layout')
		},
        close: function (order) {
			if(order.layout) {
				Vue.delete(order, 'layout')
			} else {
				if(this.layout(order) == 'expanded') {
					Vue.set(order, 'layout', 'compact')
				} else if(this.layout(order) == 'compact') {
					Vue.set(order, 'layout', 'table')
				}
			}
        },
		expand: function (order) {
			if(this.layout(order) == 'compact' || this.layout(order) == 'table') {
				if(this.view_layout == 'expanded') {
					Vue.delete(order, 'layout')
				} else {
					Vue.set(order, 'layout', 'expanded')
				}
			} else {
				Vue.delete(order, 'layout')
			}
		},
		action: function (event, orders) {
			switch(event) {
				case 'invoice':
					this.$refs.template.open('invoice', orders)
				break;
				case 'picklist':
					this.$refs.template.open('picklist', orders)
				break;
				case 'fulfill':
					this.$refs.fulfill.open(orders[0]).then((confirm) => {
						if(confirm) {
							fulfillments.fulfill_orders(orders)
						}
					})
				break;
				case 'bulk_fulfill':
					fulfillments.fulfill_orders(orders)
				break;
				case 'defer':
					orderTools.defer_orders(orders, true)
				break;
				case 'undefer':
					orderTools.defer_orders(orders, false)
				break;
				case 'refresh':
					orderTools.reset_quotes(orders)				
				break;
				case 'reset':
					this.$refs.warning.open('Warning', 'Resetting an order will clear all settings and any consignment data that is associated with the order. This process cannot be reversed.', 'Do you wish to continue?', { color: 'red darken-2' }).then((confirm) => {
						if(confirm) {
							this.$store.dispatch('Orders/reset', orders)
						}
					})
				break
				case 'settings':
					this.$refs.settings.open(this.layout)
				break;
				default:
					consignments.carrier_action(orders, this.$refs.confirm, event)
			}
		},
		get_images: function (orders) {
			let images_required = {}
			orders.forEach( (order ) => {
				order.line_items.forEach( (line_item ) => {
					if(this.images[`${line_item.product_id}|${line_item.variant_id}`] && !this.images[`${line_item.product_id}|${line_item.variant_id}`].url && !this.images[`${line_item.product_id}|${line_item.variant_id}`].failed){
						this.$store.dispatch('Images/get_image', { image: `${line_item.product_id}|${line_item.variant_id}` })
					}
				})
			})
		}, 
		paginate: function (items) {
			//this.get_images(items)
		},
		nextPage: function () {
			if (this.page + 1 <= this.numberOfPages) this.page += 1
			this.scroll()
		},
		formerPage: function () {
			if (this.page - 1 >= 1) this.page -= 1
			this.scroll()
		},
		checkMaxPage: function (val) {
			this.page = this.page > val ? val : this.page
			this.page = this.page == 0 && val > 0 ? 1 : this.page
		},
		scroll: function () {
			window.scrollTo(0, 0)	
		},
		tab_filter: function (order, tab) {
			if(!order) return false
			if(tab !== 'deferred' && order.deferred) {
				return false
			}
			let consignment_status = ''
			if(order.consignment && order.consignment.service) {
				consignment_status = this.$store.getters[`Carriers/${order.consignment.service.carrier_type}/consignment_status`](order)
			}

			switch(tab) {
                case 'all': {
					if(!order.shipping_address || !orderTools.count_fulfillable_line_items(order)) {
						return false
					}
					return true
				}
                case 'no_label': {
					if(!order.shipping_address || !orderTools.count_fulfillable_line_items(order) || (order.consignment && order.consignment.shipment) || order.deferred) {
						return false
					}
					return true
				}
                case 'label_initiated': {
					if(consignment_status == 'unpaid_consignment') {
						return true
					}
					return false
				}
                case 'label_finalised': {
					if(consignment_status == 'paid_consignment') {
						return true
					}
					return false
				}
                case 'label_errors': {
					if(order.errors && order.errors.label) {
						return true
					}
					return false
				}
                case 'address_errors': {
					if(order.errors && order.errors.address && order.shipping_address) {
						return true
					}
					return false
				}
				case 'merge': {
					return true
				}
				case 'merge_errors': {
					return true
				}
                case 'not_assigned': {
					if(order.consignment || order.rate_selected !== undefined || !order.shipping_address) {
						return false
					}
					return true
				}
				case 'pickup': {
					if(!order.shipping_address && orderTools.count_fulfillable_line_items(order)) {
						return true
					}
					return false
				}
				case 'no_shipping': {
					if(!orderTools.count_fulfillable_line_items(order)) {
						return true
					}
					return false
				}
                case 'deferred': {
					if(order.deferred){
						return true
					}
					return false
				}
                case 'selected': {
					let found = false
					this.selected.forEach(id => {
						if(id == order.id) {
							found = true
						}
					})
					return found
				}
                default: {
					if(order.rate_selected && order.rate_selected.carrier_id == tab) {
						return true
					}
					return false
				}
            }			
		},
		item_count: function (tab) {
			return this.orders.filter(order => {
				return this.tab_filter(order, tab) 
					&& (!this.search || [orderTools.searchable_text(order), consignments.get_tracking_number(order)].join(' ').toLowerCase().includes(this.search.toLowerCase()))
					&& (this.filtered_carrier ? this.tab_filter(order, this.filtered_carrier) : true)
            }).length
		},
        line_items_count: function (order) {
			const fulfillable_items = orderTools.count_fulfillable_line_items(order)
            return `${fulfillable_items} Item${ fulfillable_items > 1 ? 's' : ''}`
        },
		reset_error: function (order, error) {
			Vue.delete(order.errors, error)
		},
        show(section, subsection = null) {
			try {
				if(subsection) {
					return this.display_settings.compact[section].subsections[subsection].status
				} else {
					return this.display_settings.compact[section].status
				}
			} catch (e) {
				return true
			}
        },
		validate_search: function () {
			this.search = this.search ? this.search : ''
		},
        layout: function (order) {
			return this.$store.getters[`Settings/order_layout`](order)
        },
        show_open(section) {
			return (this.display_settings.expanded[section].subsections.open.status ? 0 : null)
        },
        show_multiple_open(sections) {
            const arr = []
            for(let i = 0; i < sections.length; i++){
                if(this.display_settings.expanded[sections[i]].subsections.open.status) {
                    arr.push(i)
                }
            }
			return arr
        },

	},
	created: function () {
		try {
			const item = localStorage.getItem('hidden_alerts')
			if(item) {
				this.hidden_alerts = JSON.parse(item)
			}
			const sort = localStorage.getItem('sort')
			if(sort) {
				this.sortDesc = JSON.parse(sort)
			}
		} catch (e) {}
	},
	computed : {
		...mapState('Images', ['images']), 
		...mapGetters({
			loading: 'loading',
			requoting: 'Quotes/requoting',
			quotes_required: 'Quotes/quotes_required',
			quotes: 'Quotes/quotes',
			orders: 'Orders/orders',
			new_orders: 'Orders/new_orders',
			carriers: 'Carriers/carriers',
			errors: 'Orders/errors',
			display_settings: 'Settings/display_settings',
			view_layout: 'Settings/layout',
			itemsPerPage: 'Settings/items_per_page',
			delay_new_orders: 'Orders/delay_new_orders',
			popup_blocked: 'popup_blocked',
			system_error_message: 'system_error_message',
			system_error: 'system_error',
			mergeable_orders: 'Orders/mergeable_orders',
			unmerged: 'Merge/unmerged',
			partial: 'Merge/partial',
			merge_errors: 'Merge/errors'
		}),
        fulfillment_errors () {
            return this.errors.filter(order => order.errors.fulfillment)
        },
        label_errors () {
            return this.errors.filter(order => order.errors.label)
        },
        quote_errors () {
            return this.errors.filter(order => order.errors.quote)
        },
        address_errors () {
            return this.errors.filter(order => order.errors.address && order.shipping_address)
        },
		carriers_with_orders () {
			const arr = []
			for(const carrier in this.carriers) {
				if(this.item_count(carrier)) {
					arr.push(this.carriers[carrier])
				} 
			}
			return arr
		},
		numberOfPages () {
			return Math.ceil(this.filteredItems.length / this.itemsPerPage[this.layout()])
		},
		filteredKeys () {
			return this.keys.filter(key => key !== 'Name')
		},
		filteredItems () {
			const merged = []
			this.orders.forEach(o => {
				const m = this.$store.getters['Merge/order'](o)
				if(m) {
					merged.push(m)
				}
			})
			return merged.filter(order => {
				return mergeTools.visible(order) 
					&& this.tab_filter(order, this.tab) 
					&& (!this.search || [orderTools.searchable_text(order), consignments.get_tracking_number(order)].join(' ').toLowerCase().includes(this.search.toLowerCase()))
					&& (this.filtered_carrier ? this.tab_filter(order, this.filtered_carrier) : true)
            })
		},
		tab_name () {
			switch(this.tab) {
				case 'all': return null
				case 'no_label': return null
				case 'label_initiated': return null
				case 'label_finalised': return null
				case 'selected': return null
				case 'label_errors': return null
				case 'merge': return null
				case 'merge_errors': return null
				case 'address_errors': return null
				case 'not_assigned': return 'No Carrier Assigned'
				case 'pickup': return 'Pickup Orders'
				case 'no_shipping': return 'Shipping not required'
				case 'deferred': return null
				default: {
					return this.carriers[this.tab].name
				}
			}			
		},
		selected_orders () {
			const arr = []
			this.selected.forEach(id => {
				const order = this.orders.filter(order => {
					return order.id === id
				})
				if(order[0]) {
					arr.push(order[0])
				} else {
					this.selected = this.selected.filter(e => e !== id)
				}
			})
			return arr
		},
		sorted_selected_orders () {
			return _.orderBy(this.selected_orders, ['id'], [ this.sortDesc ? 'desc' : 'asc' ]);
		},
		has_deferrals () {
			let has_deferred = false
			this.selected_orders.forEach( order => {
				if(order && order.deferred) {
					has_deferred = true
				}
			})
			return has_deferred
		},
		breakpoint () {
			return this.$vuetify.breakpoint.name
		},
		button_size () {
			const size = { xs: 'x-small', sm: 'large', md: 'large', lg: 'large', xl: 'large'}[this.$vuetify.breakpoint.name];
			return size ? { [size]: true } : {}
		},	
		header_size () {
			const size = { xs: 128, sm: 128, md: 88, lg: 64, xl: 64 };
			return size[this.$vuetify.breakpoint.name]
		},	
        isSafari() {
            return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;
        },
		carriers_filter() {
//			console.log('carriers length', this.carriers.length)
			const arr = []
			if(Object.keys(this.carriers).length > 1){
//				arr.push({
//					text: 'All Carriers',
//					value: null
//				})
				Object.keys(this.carriers).forEach(c => {
					arr.push({
						text: this.carriers[c].name || this.carriers[c].carrier,
						value: this.carriers[c].carrier_id
					})
				})
			}
			return arr
		}
	}	
}
</script>

<style scoped>
	.select-box .v-input__slot {
	align-items: center;
	justify-content: center;
	}
	.mt-20 {
		margin-top: 80px;
	}
	.pointer {
		cursor: pointer;
	}
    .v-expansion-panels.condensed .v-expansion-panel-header {
        padding-top: 2px;
        padding-bottom: 2px;
        min-height: 36px;
    }
	.xdebug-border {
		border: 1px solid blue;
		padding: 1px;
	}
	.xdebug-border .debug-border {
		border: 1px solid red;
		padding: 1px;
	}
	.xdebug-border .debug-border .debug-border{
		border: 1px solid green;
		padding: 1px;
	}
    .bordered .v-expansion-panel {
        border-bottom: 1px solid #dddddd;
    }
    .bordered .v-expansion-panel:last-child {
        border-bottom: none;
    }

</style>