<template>
    <div>
        <v-row no-gutters>
            <v-col cols="1" class="py-0 debug-border">
                <div class="h5 py-0 mb-0" @click="expand(order)">
                    <span v-if="show('order_summary', 'order_number_prefix')"><div v-for="(order_name, index) in merged.order_names(order)" :key="index">{{ order_name }}</div></span>
                    <span v-else><div v-for="(order_number, index) in merged.order_numbers(order)" :key="index">{{ order_number }}</div></span>
                </div>
                <div>
                    <span class="caption py-0" v-if="show('order_summary', 'order_date')">{{ order.created_at | formatDate('DD/MM/YYYY') }} </span>
                    <span class="caption py-0" v-if="show('order_summary', 'order_time')">{{ order.created_at | formatDate('hh:mm a') }}</span>
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-if="order.financial_status == 'pending'" x-small class="py-2" color="error" v-bind="attrs" v-on="on">Pending</v-chip>    
                    </template>
                    <span>CAUTION: Payment is pending on this order</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-if="show('order_summary', 'partially_fulfilled') && orderTools.get_fulfillment_status(order)[0]" x-small outlined class="ma-2 py-2" color="primary" v-bind="attrs" v-on="on">{{ orderTools.get_fulfillment_status(order)[1] }}</v-chip>
                    </template>
                    <span>Order has been partially fulfilled</span>
                </v-tooltip>
            </v-col>
            <v-col cols="1" class="py-0 debug-border" v-if="show('order_summary', 'order_total') || show('order_summary', 'partially_fulfilled')">
                <div class="font-weight-bold" v-if="show('order_summary', 'order_total')">${{ order.current_total_price | formatNumber(2) }}</div>
            </v-col>
            <v-col cols="2" class="py-0 debug-border">
                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                    <Customer :order="order"/> 
                </v-lazy>
            </v-col>

            <v-col :cols="show('order_summary', 'order_total') || show('order_summary', 'partially_fulfilled') ? 8 : 9" class="pa-0 debug-border-blue">
                <!-- Not showing products -->
                <v-row no-gutters v-if="1==2 && !showing_products">
                    <v-col cols="5" class="py-0">
                        <v-row no-gutters>
                            <v-col :cols="showing_packages ? 4 : 6" class="py-0 px-1 debug-border">
                                <Items :order="order" v-on:toggle_products="toggle_products($event)"/> 
                            </v-col>
                            <v-col :cols="showing_packages ? 8 : 6" class="py-0 pl-0 debug-border">
                                <Packages :order="order"/> 
                                <v-row no-gutters v-if="show('order_summary', 'shipping_method')">
                                    <v-col class="caption pl-1 mt-2">
                                        <span class="font-weight-bold">Shipping Selected:</span> {{ order.shipping_lines[0] ? order.shipping_lines[0].title : 'Not specified' }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="7" class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="6" class="py-1 pl-0 debug-border">
                                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                                    <Consignments v-if="order.consignment" :order="order"/> 
                                </v-lazy>
                                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                                    <Rates v-if="!order.consignment || !order.consignment.shipment" :order="order"/> 
                                </v-lazy>
                            </v-col>    
                            <v-col cols="6" class="py-1 debug-border">
                                <Actions :order="order" class="pr-2" v-on:action="$emit('action', $event)" v-on:close="$emit('close')" v-on:expand="$emit('expand')"/> 
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>

                <!-- Showing products -->
                <v-row no-gutters>
                    <v-col cols="5" class="py-0">
                        <v-row no-gutters>
                            <v-col :cols="12" class="py-0 px-1 debug-border">
                                <Items :order="order" v-on:toggle_products="toggle_products($event)"/> 
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="7" class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="6" class="py-0 pl-0 debug-border">
                                <Packages :order="order"/> 
                                <v-row no-gutters v-if="show('order_summary', 'shipping_method')">
                                    <v-col class="caption pl-1 mt-2">
                                        <span class="font-weight-bold">Shipping Selected:</span> {{ order.shipping_lines[0] ? order.shipping_lines[0].title : 'Not specified' }} <span v-if="show('order_summary', 'shipping_price')">${{ order.shipping_lines[0] ? order.shipping_lines[0].discounted_price : 0  | formatNumber(2) }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="py-0 pr-2 pl-0 debug-border">
                                <Actions :order="order" class="pr-2" v-on:action="$emit('action', $event)" v-on:close="$emit('close')" v-on:expand="$emit('expand')"/> 
                                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                                    <Consignments v-if="order.consignment" :order="order"/> 
                                </v-lazy>
                                <v-lazy :options="{threshold: .0}" transition="fade-transition">
                                    <Rates v-if="!order.consignment || !order.consignment.shipment" :order="order"/> 
                                </v-lazy>
                            </v-col>

                        </v-row>
                    </v-col>

                </v-row>
            </v-col>



        </v-row>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Customer from '@/V2/components/Orders/TableView/Customer'
import Rates from '@/V2/components/Orders/Rates'
import Consignments from '@/V2/components/Orders/Consignments'
import Packages from '@/V2/components/Orders/TableView/Packages'
import Items from '@/V2/components/Orders/TableView/Items'
import Actions from '@/V2/components/Orders/Actions'
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
            products_expanded: false,
            merged: mergeTools
        }
    },
    components: {
        Customer,
        Rates,
        Consignments,
        Packages,
        Items,
        Actions
    },
    methods: {
        expand: function () {
            Vue.set(this.order, 'layout', 'expanded')
        },
        show(section, subsection) {
			try {
				if(subsection) {
					return this.display_settings.table[section].subsections[subsection].status
				} else {
					return this.display_settings.table[section].status
				}
			} catch (e) {
				return true
			}
        },
        toggle_products(expanded) {
            this.products_expanded = expanded
        }
    },
    computed: {
		...mapGetters({
			display_settings: 'Settings/display_settings'
		}),
        showing_products() {
            if(this.products_expanded) {
                return true
            }
            try {
                return this.display_settings.table.products.subsections.open.status
            } catch (e) {
                return false
            }
        },
        showing_packages() {
            try {
                return this.display_settings.table.packages.subsections.open.status
            } catch (e) {
                return false
            }
        },
    }
}
</script>

<style scoped>
    .xdebug-border {
        border: 1px solid red
    }
    .xdebug-border-blue {
        border: 1px solid blue
    }
</style>