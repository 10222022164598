import store from "@/V2/store/index"
import Vue from 'vue'
import orderTools from "@/V2/helpers/orderTools";
import axios from 'axios'
import { packages } from '@/V2/integrations/MyPost/store/mypost_packages'
import { products } from '@/V2/integrations/MyPost/store/mypost_products'
import { errors } from '@/V2/integrations/MyPost/store/mypost_errors'
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    get_settings: function (orders) {
        const carrier = {
            account_number: '',
            merchant_token: '',
            printer: {},
            pickup: false,
            payment: 'auto',
            payment_method: 'STORED_PAYMENT',
            charge_to_account: null
        } 
        for(const order of orders) {
            if(order.consignment && order.consignment.service) {
                try {
                    carrier.account_number = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.account_number
                    carrier.merchant_token = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.merchant_token
                    carrier.printer = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.printer
                    carrier.pickup = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.pickup == 'Y' ? true : false
                    carrier.payment = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.payment || 'auto'
                    carrier.payment_method = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.payment_method || 'STORED_PAYMENT'
                    carrier.charge_to_account = store.getters['Carriers/carrier'](order.consignment.service.carrier_id).settings.account_number
                    return carrier
    
                } catch (e) {
                    console.log(e)
                    return null
                }
            }
        }
    },
    get_hidden_services: function (carrier) {
        const hidden = {}
        if(carrier && carrier.settings.hidden) {
            carrier.settings.hidden.forEach(code => {
                hidden[code] = true
            })
        }
        return hidden
    },
    create_labels: async function (payload) {
        store.commit('set_progress', { status: true, message: 'Creating shipments' }) 
        const settings = this.get_settings(payload.orders)
        
        payload.orders = await this.create_consignments(payload.orders, payload.action)
        store.commit('set_progress', { status: true, message: 'Validating shipments' }) 
        if(payload.action == 'reprint') {
            await this.print_label(payload.orders, settings)
        } else if((settings && (settings.payment == 'auto' || !settings.payment)) || payload.action == 'pay_print') {
            if(await this.create_order(payload, settings)) {
                store.commit('set_progress', { status: true, message: 'Generating labels' }) 
                await this.print_label(payload.orders, settings)
            }
        }

        store.commit('set_progress', { status: false, message: null }) 
    },
    get_labels: async function (request_id, settings) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/get`, {},
        {
            headers: { 
                'Authorization': token,
                'account_number': settings.account_number,
                'merchant_token': settings.merchant_token,
                'resource': `/shipping/v1/labels/${request_id}`
            }
        })
    },
    print_label: async function (orders, settings) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        for(const order of orders){
            if(order.consignment.shipment && !(order.errors && order.errors.label)){
                shipments.push( { 'shipment_id': order.consignment.shipment.shipment_id } )
            }
        }
        if(shipments.length){
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, 
            {
                'wait_for_label_url': false,
                'preferences': [
                    {
                        'type': 'PRINT',
                        'format': 'PDF',
                        'groups': [
                            {
                                'group': 'Parcel Post',
                                'layout': settings.printer.parcel_post.label,
                                'branded': settings.printer.parcel_post.branded,
                                'left_offset': settings.printer.parcel_post.left_offset ? settings.printer.parcel_post.left_offset : 0,
                                'top_offset': settings.printer.parcel_post.right_offset ? settings.printer.parcel_post.right_offset : 0
                            },
                            {
                                'group': 'Express Post',
                                'layout': settings.printer.express_post.label,
                                'branded': settings.printer.express_post.branded,
                                'left_offset': settings.printer.express_post.left_offset ? settings.printer.express_post.left_offset : 0,
                                'top_offset': settings.printer.express_post.right_offset ? settings.printer.express_post.right_offset : 0,
                            }
                        ]
                    }
                ],
                'shipments': shipments
            },
            {
                headers: { 
                    'Authorization': token,
                    'account_number': settings.account_number,
                    'merchant_token': settings.merchant_token,
                    'method' : 'POST',
                    'resource': `/shipping/v1/labels`
                }
            })
            .then(async(response) => {
                if(response.data.body.labels) {
                    let labels_are_pending = true
                    while(labels_are_pending){
                        await this.get_labels(response.data.body.labels[0].request_id, settings).then(response => {
                            if(response.data.body.labels[0].status != 'PENDING') {
                                const labelWindow = window.open(response.data.body.labels[0].url, '_blank');

                                if(!labelWindow || labelWindow.closed || typeof labelWindow.closed=='undefined') 
                                { 
                                    store.commit('set_popup_blocked', response.data.body.labels[0].url)
                                }
                                labels_are_pending = false
                            }
                        })
                    }
                    
                }
                if(response.data.body.errors) {
                    for(const error of response.data.body.errors) {
                        const order = orders.find( o => {
                            return o.consignment.shipment && o.consignment.shipment.shipment_id == error.context.shipment_id   
                        })
                        store.dispatch('Orders/init_error', { order: order, type: 'label', error: this.nicer_message(error) }, { root: true })
                    }
                }
                return true
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'mypostTools/print_labels', data: err})
            })
        }
    },
    create_consignments: async function (orders, action) {
        const shipments: Array<any> = []
        const create_shipments_arr: Array<any> = []
        const update_shipments_arr: Array<any> = []
        const events: Array<any> = []
        let event_type = ''
        let errors = 0

        for(const order of orders){
            this.set_order_options({order: order, carrier_id: order.consignment.service.carrier_id})
            order.consignment.data['shipment_request'] = this.build_shipment(order)
            if(!order.consignment.order){
                if(order.consignment.shipment) {
                    if(action != 'pay' && action != 'pay_print'){
                        update_shipments_arr.push(order)
                        event_type = 'UpdateShipment'
                    }
                } else {
                    create_shipments_arr.push(order)
                    event_type = 'CreateShipment'
                }

                events.push({
                    id: order.id,
                    data: orderTools.loggable_data(order),
                    type: event_type,
                })
            }
        }

        orderTools.log(events)
        if(create_shipments_arr.length){
            await this.create_shipments(create_shipments_arr).then(new_errors => {
                errors += new_errors
            })
        }
        if(update_shipments_arr.length){
            await this.update_shipments(update_shipments_arr).then(new_errors => {
                errors += new_errors
            })
        }

        if(!errors) {
            return orders
        }
        return []
 
    },
    create_shipments: async function(orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        for(const order of orders) {
            shipments.push(order.consignment.data.shipment_request)
        }
        store.commit('set_progress', { status: true, message: 'Sending shipments to Australia Post' }) 

        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, 
        {
            shipments: shipments
        },
        {
            headers: { 
                'Authorization': token,
                'account_number': store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials.account_number,
                'merchant_token': store.getters['Carriers/carrier'](orders[0].consignment.service.carrier_id).credentials.merchant_token,
                'resource': '/shipping/v1/shipments'
            }
        })
        .then(response => {
            store.commit('set_progress', { status: true, message: 'Shipments sent' }) 
            if(response.data.body.errorCode) {
                const message = this.nicer_message({ code: response.data.body.errorCode })
                for(const order of orders) {
                    Vue.delete(order, 'consignment')
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
                return 1
            }
            if(response.data.body.errors) {
                for(const error of response.data.body.errors) {
                    const shipment_index = this.get_shipment_index_from_error(error)
                    const order = orders.find( o => {
                        return o.id == shipments[shipment_index].shipment_reference   
                    })
                    Vue.delete(order, 'consignment')
                    const message = this.nicer_message(error)
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                    orderTools.log([{
                        id: order.id,
                        type: 'ERROR',
                        data: message
                    }])
                }
                return 1
            }
            if(response.data.body.shipments) {
                for(const shipment of response.data.body.shipments) {
                    const order = orders.find( o => {
                        return o.id == shipment.shipment_reference   
                    })
                    Vue.set(order.consignment, 'shipment', shipment)
                    Vue.set(order, 'save_event', 'ShipmentCreated')
                    store.dispatch('Orders/save', order)
                }
                return 0
            }
            return 0
        })
        .catch(err => {
            orderTools.log_error({message: 'Network Error', stack: 'mypostTools/create_shipments', data: err})
            return 0
        })
    },
    get_shipment_index_from_error: function (error) {
        if(error.context) {
            if(error.context.shipment_index) {
                return error.context.shipment_index
            }
        }
        if(error.field) {
            const fields = error.field.split('.')
            const index = fields[0] ? fields[0].match(/\d+/) : null
            if(index) {
                return index[0]
            }
        }
    },
    update_shipments: async function (orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const requests: Array<any> = []
        let has_errors = 0
        for(const order of orders) {
            const request = await axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, order.consignment.data.shipment_request,
            {
                headers: { 
                    'Authorization': token,
                    'account_number': store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.account_number,
                    'merchant_token': store.getters['Carriers/carrier'](order.consignment.service.carrier_id).credentials.merchant_token,
                    'method' : 'PUT',
                    'resource': `/shipping/v1/shipments/${order.consignment.shipment.shipment_id}`
                }
            })
            .then(response => {
                if(response.data.body.errorCode) {
                    const message = this.nicer_message({ code: response.data.body.errorCode })
                    for(const order of orders) {
                        Vue.delete(order, 'consignment')
                        store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                        orderTools.log([{
                            id: order.id,
                            type: 'ERROR',
                            data: message
                        }])
                    }
                    return 1
                }
                if(response.data.body.errors) {
                    for(const error of response.data.body.errors) {
                        Vue.delete(order, 'consignment')
                        const message = this.nicer_message(error)
                        store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                        has_errors++
                        orderTools.log([{
                            id: order.id,
                            type: 'ERROR',
                            data: message
                        }])
                    }
                } 
                if(response.data.body.shipment_id) {
                    Vue.set(order.consignment, 'shipment', response.data.body)
                    Vue.set(order, 'save_event', 'ShipmentUpdated')
                    store.dispatch('Orders/save', order)
                }
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'mypostTools/update_shipments', data: err})
            })
            requests.push(request)
        }
        return Promise.all(requests).then( (values) => {
            return has_errors
        })
    },
    create_order: async function (payload, settings) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const shipments: Array<any> = []
        const shipment_ids: Array<any> = []
        let pickup = {pickup_id: null}

        for(const order of payload.orders){
            if(order.consignment.shipment && !order.consignment.order && !(order.errors && order.errors.label)) {
                shipments.push( { 'shipment_id': order.consignment.shipment.shipment_id } )
                shipment_ids.push(order.consignment.shipment.shipment_id)
            }
        }
        if(shipments.length){
            let ok_to_continue = true
            if(settings.pickup) {
                //commit('set_progress', { status: false }, {root: true}) 
                ok_to_continue = await payload.dialog.open('MyPost Pickup Booking', 'MyPost', 'PickupDialog', { shipments: shipment_ids}).then(async (response) => {
                    if(response.answer == 'YES') {
                        return await payload.dialog.open('Verify the pickup address', 'MyPost', 'PickupAddress', response.payload).then( async (response) => {
                            if(response.answer == 'YES') {
                                return await payload.dialog.open('Schedule the pickup', 'MyPost', 'PickupSchedule', {pickups: response.payload, settings: settings}).then( async (response) => {
                                    if(response.answer == 'YES') {
                                        pickup = response.payload
                                        return true
                                    }
                                })
                            }
                        })
                        
                    } else if(response.answer == 'NO') {
                        return true
                    } else if(response.answer == 'CANCEL') {
                        return false
                    }
                })
            }
            if(!ok_to_continue) {
                return false
            }

            if(settings.payment_method == 'PAYPAL') {
                for(const order of payload.orders) {
                    store.dispatch('Orders/init_error', { order: order, type: 'label', error: 'PayPal payments must be completed from within the MyPost Business web site' }, { root: true })
                }
                return false
            }

            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, 
            {
                'payment_method': settings.payment_method,
                'shipments': shipments,
                'pickups': pickup.pickup_id ? [{ pickup_id: pickup.pickup_id }] : null
            },
            {
                headers: { 
                    'Authorization': token,
                    'account_number': settings.account_number,
                    'merchant_token': settings.merchant_token,
                    'method' : 'PUT',
                    'resource': `/shipping/v1/orders`
                }
            })
            .then(async(response) => {
                if(response.data.body.order) {
                    const events: Array<any> = []
                    const order_summary = {
                        order_id: response.data.body.order.order_id,
                        order_creation_date: response.data.body.order.order_creation_date,
                        order_summary: response.data.body.order.order_summary
                    }
                    for(const shipment of response.data.body.order.shipments) {
                        const order = payload.orders.find( order => {
                            return order.consignment.shipment.shipment_id == shipment.shipment_id
                        })
                        Vue.set(order.consignment, 'order', order_summary)
                        Vue.set(order.consignment, 'locked', true)
                        Vue.set(order.consignment.order, 'shipment', shipment)
                        Vue.set(order.consignment.order, 'payment_method', settings.payment_method)
                        Vue.set(order, 'save_event', 'NoChange')
                        store.dispatch('Orders/save', order)

                        events.push({
                            id: order.id,
                            type: 'CreateOrder',
                            data: orderTools.loggable_data(order)
                        })
                    }
                    orderTools.log(events)
                    if(settings.payment_method == 'CHARGE_TO_ACCOUNT') {
                        await this.pay_by_charge_account(response.data.body.order.order_id, settings)
                    }
                }
                if(response.data.body.errors) {
                    for(const error of response.data.body.errors) {
                        if(error.context && error.context.shipment_id) {
                            const order = payload.orders.find( o => {
                                return o.consignment.shipment.shipment_id == error.context.shipment_id 
                            })
                            const message = this.nicer_message(error)
                            store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                            orderTools.log([{
                                id: order.id,
                                type: 'ERROR',
                                data: message
                            }])
    
                        } else {
                            for(const order of payload.orders) {
                                const message = this.nicer_message(error)
                                store.dispatch('Orders/init_error', { order: order, type: 'label', error: message }, { root: true })
                                orderTools.log([{
                                    id: order.id,
                                    type: 'ERROR',
                                    data: message
                                }])
                            }
                        }
                    }
                    return false
                }
                store.commit('set_progress', { status: false, message: null }) 
                return true
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'Fulfillments/create_fulfillment', data: err})
                return false
            })
        }
        return true
    },
    pay_by_charge_account: async function(order_id, settings) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, 
            {
                payment_method: settings.payment_method,
                order_id: order_id,
                attributes: {'account-number': settings.charge_to_account}
            },
            {
                headers: { 
                    'Authorization': token,
                    'account_number': settings.account_number,
                    'merchant_token': settings.merchant_token,
                    'method' : 'POST',
                    'resource': `/shipping/v1/payments`
                }
            }
        )
    },
    delete_shipments: async function (orders) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const settings = this.get_settings(orders)
        const shipment_ids: Array<any> = []
        for(const order of orders) {
            if(order.consignment && order.consignment.shipment) {
                shipment_ids.push(order.consignment.shipment.shipment_id)
            }
            if(order.consignment) {
                Vue.set(order, 'deleted_consignment', order.consignment)
                Vue.delete(order, 'consignment')
                Vue.set(order, 'save_event', 'DeleteConsignment')
                store.dispatch('Orders/save', order)
            }
        }
        if(settings) {
            while(shipment_ids.length > 0) {
                const ids = shipment_ids.splice(0, 1)
                const query =  encodeURI(`shipment_ids=${ids}`)

                axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/post`, {},
                    {
                        headers: { 
                            'Authorization': token,
                            'account_number': settings.account_number,
                            'merchant_token': settings.merchant_token,
                            'method' : 'DELETE',
                            'resource': `/shipping/v1/shipments?${query}`
                        }
                    }
                )
            }
        }

    },
    get_shipments: async function (ids, carrier) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        const query =  `shipment_ids=${ids}`

        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/get`, 
        {
            query: encodeURI(query)
        },
        {
            headers: { 
                'Authorization': token,
                'account_number': carrier.credentials.account_number,
                'merchant_token': carrier.credentials.merchant_token,
                'resource': `/shipping/v1/shipments`
            }
        })
    },
    get_order: async function (order_id, carrier) {
        const token = await getSessionToken(store.getters['Shopify/app']);
        return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/carriers/mypost/get`, {},
        {
            headers: { 
                'Authorization': token,
                'account_number': carrier.credentials.account_number,
                'merchant_token': carrier.credentials.merchant_token,
                'resource': `/shipping/v1/orders/${order_id}`
            }
        })
    },
    build_shipment: function (order) {
        const options = order.orderOptions[order.consignment.service.carrier_id][orderTools.is_domestic(order) ? 'domestic' : 'international']
        const items = orderTools.is_domestic(order) ? this.build_domestic_items(order) : this.build_international_items(order)
        if(items){
            const shipment = {
                shipment_reference: order.id,
                sender_references: orderTools.get_reference(order, 50),
                email_tracking_enabled: options.email_tracking && order.email ? true : false,
                from: {
                    suburb: store.getters['User/address'].suburb ? store.getters['User/address'].suburb.trim() : '',
                    state: orderTools.abbreviated_state(store.getters['User/address'].state),
                    postcode: store.getters['User/address'].postcode ? store.getters['User/address'].postcode.trim() : '',
                    country: store.getters['User/address'].country,
    
                    business_name: store.getters['User/address'].company,
                    name: store.getters['User/address'].name,
                    phone: store.getters['User/address'].phone ? store.getters['User/address'].phone.trim() : '',
                    email: store.getters['User/address'].email,
                    lines: [
                        store.getters['User/address'].address1,
                        store.getters['User/address'].address2
                    ]
                },
                items: [items],
                to: {
                    business_name: order.shipping_address.company,
                    name: order.shipping_address.name,
                    phone: order.shipping_address.phone ? order.shipping_address.phone.trim() : (order.customer && order.customer.phone ? order.customer.phone.trim() : ''),
                    postcode: order.shipping_address.zip,
                    state: orderTools.abbreviated_state(order.shipping_address.province_code),
                    suburb: order.shipping_address.city ? order.shipping_address.city.trim() : '',
                    lines: [
                        order.shipping_address.address1,
                        order.shipping_address.address2
                    ],
                    country: order.shipping_address.country_code,
                    email: order.email,
                    type: "STANDARD_ADDRESS",
                }
            }
            return shipment
        }
        return
    },
    build_domestic_items: function (order) {
        const items = {}
        const options = order.orderOptions[order.consignment.service.carrier_id]
        if(orderTools.get_package(order).length > 1) {
            store.dispatch('Orders/init_error', { order: order, type: 'packages', error: 'MyPost Business does not support multiple packages in a single consignment.' }, { root: true }) 
            return
        }
        const order_package = orderTools.get_package(order)[0]

        items['contains_dangerous_goods'] = false
        items['product_id'] = order.consignment.service.code

        items['features'] = this.build_domestic_features(options)

        if(this.is_volumetric(order.consignment.service.code)){
            items['weight'] = Vue.filter('formatNumber')(orderTools.get_total_package_weight(order) / 1000, 3)
            items['length'] = order_package.dimensions[0] ? Vue.filter('formatNumber')(order_package.dimensions[0], 1) : 5
            items['width'] = order_package.dimensions[1] ? Vue.filter('formatNumber')(order_package.dimensions[1], 1) : 5
            items['height'] = order_package.dimensions[2] ? Vue.filter('formatNumber')(order_package.dimensions[2], 1) : 5
        }

        return items
    },
    build_domestic_features: function (options) {
        const features = {}
        if(options.domestic && options.domestic.transit_cover == true && Number(options.domestic.transit_cover_amount) >= 1) {
            features['TRANSIT_COVER'] = {
                attributes: {
                    cover_amount: Number(options.domestic.transit_cover_amount)
                }
            }
            if(Number(options.domestic.transit_cover_amount) > 500){
                options.domestic.signature_on_delivery = true     
            }
        }
        if(options.domestic && options.domestic.signature_on_delivery) {
            features['SIGNATURE_ON_DELIVERY'] = {}
        }
        return features
    },
    build_international_items: function (order) {
        const items = {}
        const options = order.orderOptions[order.consignment.service.carrier_id]
        if(options.international.commercial_value == undefined) {
            options.international.commercial_value = true
        }

        if(options.international.commercial_value == true && !options.international.country_of_origin) {
            options.international.country_of_origin = 'AU'
        }
        
        if(orderTools.get_package(order).length > 1) {
            store.dispatch('Orders/init_error', { order: order, type: 'packages', error: 'MyPost Business does not support multiple packages in a single consignment.' }, { root: true }) 
            return
        }
        const order_package = orderTools.get_package(order)[0]
        items['international_parcel_sender_name'] = options.international.international_parcel_sender_name,
        items['contains_dangerous_goods'] = false
        items['commercial_value'] = options.international.commercial_value
        items['classification_type'] = options.international.classification_type
        if(items['classification_type'] === 'OTHER') {
            items['description_of_other'] = options.international.description_of_other
        }
        items['non_delivery_action'] = options.international.non_delivery_action
        items['export_declaration_number'] = options.international.export_declaration_number
        items['import_reference_number'] = options.international.import_reference_number,
        items['certificate_number'] = options.international.certificate_number,
        items['licence_number'] = options.international.licence_number,
        items['comments'] =  options.international.comments,


        items['product_id'] = order.consignment.service.code

        items['features'] = this.build_international_features(order, options, false)

        items['weight'] = Vue.filter('formatNumber')(orderTools.get_total_package_weight(order) / 1000, 3)
        items['length'] = order_package.dimensions[0] ? Vue.filter('formatNumber')(order_package.dimensions[0], 1) : 5
        items['width'] = order_package.dimensions[1] ? Vue.filter('formatNumber')(order_package.dimensions[1], 1) : 5
        items['height'] = order_package.dimensions[2] ? Vue.filter('formatNumber')(order_package.dimensions[2], 1) : 5





        const contents: Array<any> = []
        const total_order_weight = orderTools.get_line_item_weights(order)
        console.log('total order weight', total_order_weight)
        if(options.international.description || orderTools.count_fulfillable_line_items(order) > 20) {
            contents.push({
                description: (options.international.description ? options.international.description.substring(0, 40) : null) || 'Miscellaneous Goods',
                quantity: 1,
                value: orderTools.get_order_total(order) < 1 ? 1 : Number(orderTools.get_order_total(order)).toFixed(2),
                tariff_code: options.international.tariff_code,
                country_of_origin: options.international.country_of_origin,
                weight: Vue.filter('formatNumber')(orderTools.get_total_package_weight(order) / 1000, 3)
            })
        } else {
            order.line_items.forEach(item => {
                if(item.fulfillable_quantity && item.requires_shipping) {
                    if(item.price < 1) {
                        contents.push({
                            description: `${item.fulfillable_quantity}x${item.name}`.substring(0, 40),
                            quantity: 1,
                            value: orderTools.discounted_item_price(item) * item.fulfillable_quantity < 1 ? 1 : Number(orderTools.discounted_item_price(item) * item.fulfillable_quantity).toFixed(2),
                            tariff_code: item.hs_code ? item.hs_code : options.international.tariff_code,
                            country_of_origin: item.country_of_origin ? item.country_of_origin : options.international.country_of_origin,
                            weight: total_order_weight > 0 ? (!options.international.hide_weights ? Vue.filter('formatNumber')((item.grams * item.fulfillable_quantity) / 1000, 3) : null) : null
                        })
                    } else {
                        contents.push({
                            description: `${item.name}`.substring(0, 40),
                            quantity: item.fulfillable_quantity,
                            value: orderTools.discounted_item_price(item) < 1 ? 1 : orderTools.discounted_item_price(item),
                            tariff_code: item.hs_code ? item.hs_code : options.international.tariff_code,
                            country_of_origin: item.country_of_origin ? item.country_of_origin : options.international.country_of_origin,
                            weight: total_order_weight > 0 ? (!options.international.hide_weights ?  Vue.filter('formatNumber')((item.grams * item.fulfillable_quantity) / 1000, 3) : null) : null
                        })
                    } 
                }
            })
        }
        items['item_contents'] = contents
        return items
    },
    build_international_features: function (order, options, quote_only) {
        const features = {}
        if(options.international){
            if(options.international.transit_cover == true && Number(options.international.transit_cover_amount) >= 1) {
                features['TRANSIT_COVER'] = {
                    attributes: {
                        cover_amount: Number(options.international.transit_cover_amount)
                    }
                }
            }
            if(options.international.signature_on_delivery) {
                features['SIGNATURE_ON_DELIVERY'] = {}
            }
            if(options.international.email_tracking && order.email && !quote_only) {
                features['EMAIL_TRACKING'] = {
                    attributes: {
                        email_address: order.email
                    }
                }
            }
            if(options.international.sms_tracking && store.getters['User/address'].mobile && !quote_only) {
                features['SMS_TRACKING'] = {
                    attributes: {
                        mobile_number: store.getters['User/address'].mobile
                    }
                }
            }
    
        }
        return features
    },
    is_volumetric: function (service) {
        const product = products.filter( (product) => {
            return  product.id == service
        })
        return product[0].volumetric
    },
    set_order_options: function (payload) {
        const carrier = store.getters[`Carriers/carrier`](payload.carrier_id)
        if(carrier) {
            if(!payload.order.orderOptions) {
                Vue.set(payload.order, 'orderOptions', {})
            }
            if(!payload.order.orderOptions[payload.carrier_id] || !payload.order.modified) {
                if(orderTools.is_domestic(payload.order)){
                    Vue.set(payload.order.orderOptions, payload.carrier_id, {
                        domestic: Object.assign({}, carrier.settings.domestic)
                    })
                    payload.order.orderOptions[payload.carrier_id].domestic['transit_cover_amount'] = Number(orderTools.get_order_total(payload.order) * (payload.order.orderOptions[payload.carrier_id].domestic.transit_cover_percentage / 100)).toFixed(2)
                } else {
                    Vue.set(payload.order.orderOptions, payload.carrier_id, {
                        international: Object.assign({}, carrier.settings.international)
                    })
                    payload.order.orderOptions[payload.carrier_id].international['transit_cover_amount'] = Number(orderTools.get_order_total(payload.order) * (payload.order.orderOptions[payload.carrier_id].international.transit_cover_percentage / 100)).toFixed(2)
                }
            }
        }
    },
    get_flat_rate_product_ids: function (payload) {
        const order_weight = orderTools.get_total_package_weight(payload.order)
        const isDomestic = orderTools.is_domestic(payload.order)
        if(isDomestic) {
            const flat_rate_packages: Array<any> = packages.filter( (p) => {
                return  `MyPost_${p.id}` == payload.package &&
                        order_weight >= p.min_weight && 
                        order_weight <= p.max_weight
            })
            if(flat_rate_packages.length){
                return flat_rate_packages[0].services
            }
        }
    },
    get_product_ids: function (payload) {
        const isDomestic = orderTools.is_domestic(payload.order)
        let order_weight = orderTools.get_total_package_weight(payload.order) 
        const cubic_weight = orderTools.get_cubic_weight(payload.order) 

        if(isDomestic) {
            order_weight = cubic_weight > order_weight && order_weight > 1000 ? cubic_weight : order_weight
        }
        return products.filter( (product) => {
            return  isDomestic == product.domestic &&
                    order_weight >= product.min_weight && 
                    order_weight <= product.max_weight &&
                    product.volumetric == payload.volumetric
        }).map(product => product.id)
    },
    get_rate_request_body: function (payload) {
        if(payload.order && payload.order.shipping_address){
            if(orderTools.get_package(payload.order).length > 1) {
                store.dispatch('Orders/init_error', { order: payload.order, type: 'packages', error: 'MyPost Business does not support multiple packages in a single consignment.' }, { root: true }) 
                return
            }

            const order_package = orderTools.get_package(payload.order)[0]
            this.set_order_options(payload)
            const features = orderTools.is_domestic(payload.order) ? this.build_domestic_features(payload.order.orderOptions[payload.carrier_id]) : this.build_international_features(payload.order, payload.order.orderOptions[payload.carrier_id], true)
            let product_ids: Array<any> = []
            // if the package selected is a flat rate package this will return flat rate product ids
            product_ids = this.get_flat_rate_product_ids({ volumetric: false, order: payload.order, package: order_package.selected})

            const body = {
                from: {
                    suburb: store.getters['User/address'].suburb,
                    state: orderTools.abbreviated_state(store.getters['User/address'].state),
                    postcode: store.getters['User/address'].postcode,
                    country: store.getters['User/address'].country,
                },
                to: {
                    suburb: payload.order.shipping_address.city ? payload.order.shipping_address.city.trim() : '',
                    state: orderTools.abbreviated_state(payload.order.shipping_address.province_code),
                    postcode: payload.order.shipping_address.zip,
                    country: payload.order.shipping_address.country_code
                },
                items: <any>[]
            }

            // if there is no matching flat rate product above we'll look for own packaging product ids
            if(!product_ids){
                const volumetric_product_ids = this.get_product_ids({ volumetric: true, order: payload.order })
                if(volumetric_product_ids.length) {
                    body.items.push({
                        length: order_package.dimensions[0] ? Vue.filter('formatNumber')(order_package.dimensions[0], 1) : 5,
                        width: order_package.dimensions[1] ? Vue.filter('formatNumber')(order_package.dimensions[1], 1) : 5,
                        height: order_package.dimensions[2] ? Vue.filter('formatNumber')(order_package.dimensions[2], 1) : 5,
                        weight: Vue.filter('formatNumber')(orderTools.get_total_package_weight(payload.order) / 1000, 3),
                        product_ids: volumetric_product_ids,
                        features: features
                    })
                }
            }
            
            if(product_ids && product_ids.length) {
                body.items.push({
                    product_ids: product_ids,
                    features: features
                })
            }
            if(!body.items.length) {
                store.dispatch('Orders/init_error', { order: payload.order, type: 'quote', error: 'There are no MyPost services available based on the specified weight/dimensions' }, { root: true })
            }
            return body
        }
        store.dispatch('Orders/init_error', { order: payload.order, type: 'address', error: 'No customer address available on order' }, { root: true })
    },
    shipment_price: function (shipment) {
        let plan = ''
        let discount = 0
        try {
            discount = shipment.items[0].prices[0].promotion_details[0].discount.gross_price
            plan = shipment.items[0].prices[0].promotion_details[0].discount_plan
        } catch (e) {
            plan = 'Band 0'
        }
        const net = shipment.shipment_summary.total_cost
        const gross = net + discount
        return { plan: plan, net: net, discount: discount, gross: gross }
    },
    nicer_message: function (error) {
        if(errors[error.code]) {
            return errors[error.code]
        }
        if(errors[error.message]) {
            return errors[error.message]
        }
        for( const k in errors ) {
            if(error.message.search(k) > -1){
                return errors[k]
            } 
        }
        return error.message
    },
    get_shipment_status: function (shipment) {
        if(!shipment.shipment_summary){
            return 0
        }
        switch(shipment.shipment_summary.status) {
            case 'Created':
                return 1
            case 'Locked':
                return 2
            case 'Sealed':
                return 3
            case 'Initiated':
                return 3
            case 'In transit':
                return 4
            case 'Delivered':
                return 5
            case 'Awaiting collection':
                return 6
            case 'Possible delay':
                return 6
            case 'Unsuccessful pickup':
                return 6
            case 'Article damaged':
                return 6
            case 'Cancelled':
                return 6
            case 'Held by courier':
                return 6
            case 'Cannot be delivered':
                return 6
            case 'Track items for detailed delivery information':
                return 6
            default:
                return 99
        }
    },

}


