<template>
    <v-card flat>
        <hr class="thick" />
        <v-row dense class="font-weight-bold px-6">
            <v-col v-if="picklist_options.images.show" cols="2">Image</v-col>
            <v-col :cols="picklist_options.images.show ? 9 : 11">Item</v-col>
            <v-col cols="1" class="text-right">Quantity</v-col>
        </v-row>
        <hr class="thin" />
        <v-row v-for="item in order.line_items" :key="item.id" class="px-6">
            <template v-if="item.fulfillable_quantity || picklist_options.fulfilled">
                <v-col v-if="picklist_options.images.show" cols="2">
                    <img v-if="images[`${item.product_id}|${item.variant_id}`]" :src="images[`${item.product_id}|${item.variant_id}`].url" :style="{ 'max-width': '100px', 'max-height': '100px' }"/>
                </v-col>
                <v-col :cols="picklist_options.images.show ? 9 : 11">
                    <div>{{ item.name }}</div>
                    <div>{{ item.sku }}</div>
                    <div v-if="item.properties">
                        <v-row v-for="(p, index) of item.properties" v-bind:key="index" no-gutters>
                            <v-col class="caption font-weight-bold">{{ p.name }}:</v-col>
                            <v-col class="caption">{{ p.value }}</v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="1" class="text-right pr-5">{{ item.fulfillable_quantity }}</v-col>
                <hr class="thin" />
            </template>
        </v-row>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import orderTools from "@/V2/helpers/orderTools";

export default {
    props: ['order'],
    data: function () {
        return {
            orderTools: orderTools,
        }
    },
    computed : {
        ...mapState('User', ['invoice_options', 'picklist_options']),
        ...mapGetters({
            images: 'Images/images'
        }),
    }
}
</script>

