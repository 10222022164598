// Orders
import Vue from 'vue'
import axios from 'axios'
import consignments from "@/V2/helpers/consignments";
import orderTools from "@/V2/helpers/orderTools";
import mergeTools from "@/V2/helpers/mergeTools";
import { debug } from 'console';
import { getSessionToken } from '@shopify/app-bridge-utils';

export default {
    namespaced: true,
    state: {
        orders: [],
        new_orders: [],
        customers: {},
        delay_new_orders: false
    },
    mutations: {
		add_orders: function(state, payload) {
			state.orders = state.orders.concat(payload)
        },
        add_customers: function(state,payload) {
            payload.forEach(c => {
                Vue.set(state.customers, c.id, c)
            })
            
        },
        remove_order(state, payload) {
            state.orders = state.orders.filter( order => order.id !== payload )
        },
        update_order: function(state, payload) {
            if(orderTools.count_fulfillable_line_items(payload)) {
                state.orders = state.orders.concat(payload)
            }
        },
        update_new_orders(state, payload) {
            state.new_orders = payload
        },
        update_meta: function(state, payload) {
            const order = state.orders.find( order => order.id === payload.id )
            Vue.set(order, 'consignment', payload.consignment)
            Vue.set(order, 'orderOptions', payload.orderOptions)
            Vue.set(order, 'packages', payload.packages || [])
            Vue.set(order, 'package', payload.package)
            Vue.set(order, 'selected', payload.selected)
            Vue.set(order, 'manually_selected', payload.manually_selected)
            Vue.set(order, 'dimensions', payload.dimensions)
            Vue.set(order, 'weight', payload.weight)
            Vue.set(order, 'materials', payload.materials)
            Vue.set(order, 'deferred', payload.deferred)
//            Vue.set(order, 'completed_fulfillments', payload.completed_fulfillments)
//            Vue.set(order, 'completed_consignments', payload.completed_consignments)
            Vue.set(order, 'fulfillmentOrders', payload.fulfillmentOrders) 
            Vue.set(order, 'merge_data', payload.merge_data)
            Vue.set(order, 'override_line_items', payload.override_line_items)
            if(payload.override_line_items) {
                order.line_items.forEach(i => {
                    i.name = payload.override_line_items[i.id] ? payload.override_line_items[i.id].name : i.name
                    i.price = payload.override_line_items[i.id] ? payload.override_line_items[i.id].price : i.price
                    i.grams = payload.override_line_items[i.id] ? payload.override_line_items[i.id].grams : i.grams
                    i.fulfillable_quantity = payload.override_line_items[i.id] ? payload.override_line_items[i.id].fulfillable_quantity : i.fulfillable_quantity
                })
            } 

            Vue.set(order, 'modified', true)
        },
        reset: async function(state, order) {
            Vue.delete(order, 'orderOptions')
            Vue.delete(order, 'errors')
            Vue.delete(order, 'package')
            Vue.delete(order, 'packages')
            Vue.set(order, 'packages', [])
            Vue.delete(order, 'selected')
            Vue.delete(order, 'manually_selected')
            Vue.delete(order, 'dimensions')
            Vue.delete(order, 'weight')
            Vue.delete(order, 'materials')
            Vue.delete(order, 'modified')
            Vue.delete(order, 'deferred')
            order.line_items.forEach(i => {
                if(order.override_line_items && order.override_line_items[i.id]) {
                    i.name = order.override_line_items[i.id].orig_name
                    i.price = order.override_line_items[i.id].orig_price
                    i.grams = order.override_line_items[i.id].orig_grams
                    i.fulfillable_quantity = order.override_line_items[i.id].orig_fulfillable_quantity
                }
            })
            Vue.delete(order, 'override_line_items')
            // consignment deleted via carrier delete function
        },
        reset_rules: function(state) {
            state.orders.forEach( order => {
                Vue.delete(order, 'rule_used')
                Vue.delete(order, 'rule_keywords')
            })
        },
        set_delay_new_orders(state, status){
            state.delay_new_orders = status
        },
        set_manually_selected(state, payload) {
            const order = state.orders.find( order => order.id === payload.order.id )
            Vue.set(order, 'manually_selected', {})
            Vue.set(order.manually_selected, 'carrier_id', payload.selected.carrier_id)
            Vue.set(order.manually_selected, 'code', payload.selected.code)
        }
    },
    getters: {
		orders: state => {
			return state.orders
        },
		customers: state => {
			return state.customers
        },
		new_orders: state => {
			return state.new_orders
        },
        order: (state) => (id) => {
            return state.orders.filter(order => order.id == id)[0]
        },
        errors: state => {
            try {
                return state.orders.filter(order => order.errors)
            } catch (e) {
                return []
            }
        },
        delay_new_orders : state => {
			return state.delay_new_orders
        },
        mergeable_orders: state => {
            const mergeable = {}
            state.orders.forEach( order => {
                try {
                    const key = mergeTools.address_key(order.shipping_address)
                    if(!mergeable[key]) {
                        mergeable[key] = {
                            id: key,
                            address: order.shipping_address,
                            orders: [],
                            selected: [],
                            parent: null,
                            children: []
                        }
                    }
                    mergeable[key].orders.push({ order: order, is_merged: order.merge_data ? order.merge_data.is_merged : false })
                        
                } catch (e) {
                    console.log(e)
                }
            })
            const arr = {}
            for (const k in mergeable) {
                if(mergeable[k].orders.length > 1){
                    arr[k] = mergeable[k]
                }
            }
            return arr
        }
    },
    actions: {
        reset: async function ({ dispatch, commit }, orders) {
            await consignments.delete_labels(orders)
            for(const order of orders) {
                commit('Quotes/reset_selected', order.id, { root: true })
                commit('reset', order)
                dispatch('Quotes/reset_quote', order, { root: true })
                dispatch('save', order)
            }
        },
        init: async function ({ dispatch, state }, next = null) {
            next = await dispatch('get_orders', next)
            if(next) {
                await dispatch('init', next)
            } else {
                await dispatch('get_meta_data')
                setTimeout( () => { dispatch('get_new_orders') }, 30000); 
                return true
            }
        },
        get_meta_data: async function ({ state, dispatch }) {
            try {
                let customer_ids: Array<any> = []
                const arr = state.orders.map(function(item) {
                    return item.customer && item.customer.id;
                });
                customer_ids = [...new Set(arr)]
                while(customer_ids.length > 0) {
                    const ids = customer_ids.splice(0, 200)
                    dispatch('get_customers', {next: null, ids: ids})
                }
            } catch (e) {
                console.log(e)
            }
            return await dispatch('get_order_meta')
        },
        get_new_orders: async function ({ state, commit, dispatch, rootGetters }) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            if(!state.delay_new_orders){
                let last_id = 0
                state.orders.forEach(order => {
                    if(order) {
                        last_id = last_id < order.id ? order.id : last_id
                    }
                })
                return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders`, { 
                    version: process.env.VUE_APP_SHOPIFY_VERSION_ORDERS, 
                    fields: rootGetters['Settings/order_fields_str'],
                    pending: rootGetters['User/import_options'].pending,
                    since: last_id
                },
                {
                    headers: { 
                        'Authorization': token,
                    }
                }).then(async (response) => { 
                    if(response && response.data && response.data.orders) {
                        commit('add_orders', response.data.orders)
                        if(response.data && response.data.orders)
                        {
                            dispatch('Images/init', {}, { root: true })
                            commit('Quotes/create_quotes', response.data.orders, { root: true })
                        }
        
                        const arr: Array<any> = []
                        for(const order of response.data.orders) {
                            arr.push(order.name)
                            dispatch('Quotes/reset_quote', order, { root: true })		
                        }
                        commit('update_new_orders', arr)
    
                    }
                    setTimeout( () => { dispatch('get_new_orders') }, 30000);
                    return response
                })
                .catch(err => {
                    console.dir(err)
                    orderTools.log_error({message: 'Network Error', stack: 'orders/get_new_orders', data: err.response})
                    setTimeout( () => { dispatch('get_new_orders') }, 30000);
                })
    
            } else {
                commit('set_delay_new_orders', false)
                setTimeout( () => { dispatch('get_new_orders') }, 30000);
            }
        },
        get_order: async function ({ commit, dispatch, rootGetters }, id) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders`, { 
                version: process.env.VUE_APP_SHOPIFY_VERSION_ORDERS, 
                fields: rootGetters['Settings/order_fields_str'],
                pending: rootGetters['User/import_options'].pending,
                id: id
            },
            {
                headers: { 
                    'Authorization': token,
                }
            }).then(async (response) => { 
                commit('remove_order', id)
                if(orderTools.count_fulfillable_line_items(response.data.order)) {
                    commit('add_orders', [response.data.order])
                    await dispatch('get_order_meta')
                }
                return response
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'orders/get_order', data: err})
            })

        },
        get_orders: async function ({ commit, rootGetters }, next = null) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders`, {
                version: process.env.VUE_APP_SHOPIFY_VERSION_ORDERS, 
                fields: rootGetters['Settings/order_fields_str'],
                pending: rootGetters['User/import_options'].pending,
                next: next
            },
            {
                headers: { 
                    'Authorization': token,
                }
            }).then(response => { 
                commit('add_orders', response.data.orders)
                if(response.data.next) {
                    return response.data.next
                }
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'orders/get_orders', data: err})
            })
        },
        save: async function ({ state, rootGetters }, order) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            let orderCopy = JSON.parse(JSON.stringify(order))
            const loggable_fields = rootGetters['Settings/loggable_fields']

            Object.keys(orderCopy).forEach(e => {
                if(!loggable_fields[e]) {
                    delete orderCopy[e]
                }
            })
            try {
                if(orderCopy['consignment']) {
                    orderCopy['consignment'] = rootGetters[`Carriers/${orderCopy['consignment']['service']['carrier_type']}/redact_customer`](orderCopy['consignment'])
                }
            } catch (e) {
    
            }
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders/save`, { order: order.id, data: orderCopy },
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                if(order.save_event && order.save_event !== 'NoChange') {
                    orderTools.log([{
                        id: order.id,
                        type: order.save_event,
                        data: orderTools.loggable_data(order)
                    }])
                    Vue.delete(order, 'save_event')
                }
                return response
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'orders/save', data: err})
            })

        },
		get_order_meta: async function({ state, commit, rootGetters }, ids = null) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
            try {
                const order_ids = ids || state.orders.map(function(item) {
                    return item.id;
                });
                const requests: Array<any> = []
                while(order_ids.length > 0) {
                    const ids = order_ids.splice(0, 10)
                    const request = axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders/list`, { orders: ids},
                    {
                        headers: { 
                            'Authorization': token,
                        }
                    })
                    .then(response => {
                        if(response.data.orders.length){
                            for(const order of response.data.orders){
                                commit('update_meta', order.data)
                            }
                        }
                        return (response.data)
                    })
                    .catch(err => {
                        orderTools.log_error({message: 'Network Error', stack: 'orders/get_order_meta', data: err})
                    })
                    requests.push(request)
                }
                return Promise.all(requests).then( (values) => {
                    return true
                })
            } catch (error) {
                orderTools.log_error({message: 'Network Error', stack: 'orders/get_order_meta', data: error})
            }
        },
        get_customers: async function ({ state, commit, rootGetters, dispatch }, payload) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/shopify`, { 
                method: 'GET', 
                resource: 'customers.json',
                params: !payload.next ? `ids=${payload.ids.join(',')}&fields=id,orders_count&limit=200` : null,
                next: payload.next
            },
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                try {
                    commit('add_customers', response.data.result.customers)
                } catch (e) {
                    console.log('error', e)
                }
                if(response.data.next) {
                    dispatch('get_customers', {next: response.data.next, ids: []})
                }
                return response
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'orders/update_order', data: err})
            })
        },
        get_selected: async function ({ state }) {
            for(const order of state.orders) {
                const selected = orderTools.get_selected_rate(order)
                Vue.set(order, 'selected', selected)
            }
        },
        update_order: async function ({ rootGetters }, payload) {
            const token = await getSessionToken(rootGetters['Shopify/app']);
			return axios.post(`${process.env.VUE_APP_V2_SHOPIFY_API}/orders/update`, { order: payload.id, data: payload.data },
			{
				headers: { 
					'Authorization': token,
				}
			}).then(response => {
                return response
            })
            .catch(err => {
                orderTools.log_error({message: 'Network Error', stack: 'orders/update_order', data: err})
            })

        },
        reset_errors: function ( { state }, payload ) {
            payload.orders.forEach( order => {
                if(order && order.errors) {
                    Vue.delete(order.errors, payload.type)
                }
            })
        },
        init_error: function ({ state }, payload ) {
            // change this so that it can handle an arry of errors from multiple carriers
            if(payload.order) {
                if(!payload.order.errors) {
                    console.log('setting error', payload.order)
                    Vue.set(payload.order, 'errors', {})
                }
                if(payload.order.errors[payload.type]) {
                    console.log('error type', payload.type)
                    if(payload.order.errors[payload.type] != payload.error) {
                        payload.order.errors[payload.type] = payload.order.errors[payload.type] + '<br>' + payload.error
                    }
                } else {
                    console.log('no type', payload.type, payload.error)
                    Vue.set(payload.order.errors, payload.type, payload.error)
                }
                console.log('finally', payload.order)
            }
        },
        reset_overrides: function ( { state, dispatch }, order ) {
            if(order.override_line_items) {
                Vue.delete(order, 'override_line_items')
                dispatch('save', order)
            }
        }
    },
    modules: {
    }
}
