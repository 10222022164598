<template>
    <v-card>
        <v-card-title class="info--text">Default Package Weight</v-card-title>
        <v-row class="px-5">
            <v-col class="pb-0">
                If the aggregate weight of the items in an order is 0kg the following Default Weight will be used to request rate quotes and create consignments:
            </v-col>
        </v-row>
        <v-row class="px-5">
            <v-col cols="12" md="5" lg="2" xl="2" class="pb-0">
                <v-text-field outlined dense label="Default Weight (kg)" v-model="defaults.weight" @change="save"></v-text-field>
            </v-col>
        </v-row>

    </v-card>           
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: function () {
        return {
            help_article: 'packages_instructions',
        }
    },
	components: {
    },
    methods: {
        save: function() {
            this.$emit('save')
        },
    },
    computed: {
        ...mapState('Packages', ['defaults']),
    },
}
</script>

