<template>
    <div class="mt-n6">
        <v-row>
            <v-col class="px-4 py-1">
                <v-card>
                    <v-row no-gutters>
                        <v-col cols="12" class="pa-2">
                            <v-alert type="warning" prominent dense dismissible border="bottom" class="mb-0">Live rates are currently disabled in your store. Ensure that your settings have been thoroughly tested before you enable them.</v-alert>
                        </v-col>
                    </v-row>
                    <v-toolbar flat>
                        <v-toolbar-title>Live Checkout Rates (Status: <span class="font-weight-bold" :class="(status ? 'success--text' : 'error--text')">{{ status ? 'Active' : 'Inactive' }} </span>)</v-toolbar-title>
                        <v-switch v-model="service_status" color="success" @click="toggle" class="colored ml-4 pt-6 mb-0" :label="`Click to ${status ? 'deactivate' : 'activate'} live rates`"></v-switch>
                        <template v-slot:extension>
                            <v-tabs  show-arrows v-model="currentItem" slider-color="white">
                                <v-tab href="#tab-0">Carriers</v-tab>
                                <v-tab href="#tab-1">Default Settings</v-tab>
                                <v-tab href="#tab-2">Rules</v-tab>
                                <v-tab href="#tab-3">Product Dimensions</v-tab>
                                <v-tab href="#tab-4">Testing</v-tab>
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-tabs-items v-model="currentItem">
                        <v-tab-item value="tab-0" class="pa-2">
                            <v-card class="mt-0 pb-4" outlined>
                                <v-toolbar color="indigo" dark>
                                    <v-toolbar-title>Enabled Carriers</v-toolbar-title>
                                </v-toolbar>
                                <v-card-subtitle class="pb-0">Rate quotes will be requested from the following carriers when customers visit your checkout page</v-card-subtitle>
                                <v-card flat class="mx-2 pl-2" v-for="(carrier, id) in carriers" :key="id">
                                    <v-checkbox hide-details v-model="settings.selected_carriers" :label="carrier.name ? `${carrier.carrier} - ${carrier.name}` : carrier.carrier" :value="id" @change="save"></v-checkbox>
                                </v-card>
                            </v-card>
                            <BackupQuotes v-on:save="save"/>
                        </v-tab-item>
                        <v-tab-item value="tab-1" class="pa-2">
                            <DefaultSettings v-on:save="save"/>
                        </v-tab-item>
                        <v-tab-item value="tab-2" class="pa-2">
                            <Rules v-on:save="save"/>
                        </v-tab-item>
                        <v-tab-item value="tab-3" class="pa-2">
                            <Dimensions v-on:save="save"/>
                        </v-tab-item>
                        <v-tab-item value="tab-4" class="pa-2">
                            <Testing v-on:save="save"/>
                        </v-tab-item>
                    </v-tabs-items>

                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="alert.enabled" :color="alert.color" top timeout="10000">
            {{ alert.message }}
            <template v-slot:action="{ attrs }">
                <v-btn outlined v-bind="attrs" @click="alert.enabled = false">Close</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import BackupQuotes from '@/V2/components/LiveRates/BackupQuotes.vue'
import DefaultSettings from '@/V2/components/LiveRates/DefaultSettings.vue'
import Rules from '@/V2/components/LiveRates/Rules.vue'
import Dimensions from '@/V2/components/LiveRates/Dimensions.vue'
import Testing from '@/V2/components/LiveRates/Testing.vue'

export default {
    data: function () {
        return {
            currentItem: 0,
            service_status: false,
            alert: {
                enabled: false,
                color: '', 
                message: ''
            },

        }
    },
	components: {
        BackupQuotes,
        DefaultSettings,
        Rules,
        Dimensions,
        Testing
    },
    methods: {
        save: function () {
            this.select_first_carrier(true)
            this.$store.dispatch('LiveRates/save')
        },
        toggle: function () {
            if(this.status) {
                this.$store.dispatch('LiveRates/disable').then(response => {
                    if(response.errors) {
                        this.alert = {
                            enabled: true,
                            color: 'red',
                            message: `An error occurred disabling Live Rates. Shopify's response was : ${response.errors}.`
                        }
                    } else {
                        this.alert = {
                            enabled: true,
                            color: 'orange',
                            message: `Shopify Live Rates has been disabled. You should check your Shopify Shipping configuration to ensure rates are being displayed.`
                        }
                    }
                    this.service_status = this.$store.getters['LiveRates/status']
                })
            } else {
                this.$store.dispatch('LiveRates/enable').then(response => {
                    if(response.id) {
                        this.alert = {
                            enabled: true,
                            color: 'green',
                            message: `Shopify Live Rates has been enabled.`
                        }
                    } else {
                        try {
                            this.alert = {
                                enabled: true,
                                color: 'red',
                                message: `An error occurred enabling Live Rates. Shopify's response was : ${response.errors.base[0]}`
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    this.service_status = this.$store.getters['LiveRates/status']
                })
            }
            this.save()
        },
        select_first_carrier: function (save = false) {
            if(!this.settings.selected_carriers.length && this.service_status){
                if(Object.keys(this.carriers).length == 1) {
                    this.settings.selected_carriers.push(Object.keys(this.carriers)[0])
                    if(save) {
                        this.alert = {
                            enabled: true,
                            color: 'red',
                            message: `At least one carrier must be selected.`
                        }
                    }
                }
            }
        }
    },
    mounted: function() {
        this.service_status = this.$store.getters['LiveRates/status']
        this.select_first_carrier()
    },
    computed: {
		...mapState('LiveRates', ['carrier_service', 'settings']), 
		...mapGetters({
			carriers: 'Carriers/carriers',
        }),
        status: {
            get () { 
                return this.$store.getters['LiveRates/status']
            },
            set (value) { this.$store.dispatch('LiveRates/disable') }
        }
    }
}
</script>

<style>
.colored .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) .v-input--switch__thumb {
  color: red
}
.colored .theme--light.v-input--switch .v-input--switch__track {
  color: red
}
</style>